import uFuzzy from '@leeoniya/ufuzzy';

export const sortFuzzy = (haystack: string[], needle: string) => {
  const uf = new uFuzzy();
  const idxs = uf.filter(haystack, needle);
  const returnValue = [];

  if (idxs != null && idxs.length > 0) {
    const info = uf.info(idxs, haystack, needle);

    const order = uf.sort(info, haystack, needle);

    for (let i = 0; i < order.length; i++) {
      returnValue.push(haystack[info.idx[order[i]]]);
    }
  }

  return returnValue;
};

export const sortFuzzyCollection = <T>(
  haystack: T[],
  needle: string,
  getNeedle: (item: T) => string,
): T[] => {
  const uf = new uFuzzy();
  const names = haystack.map(getNeedle);
  const idxs = uf.filter(names, needle);

  if (!idxs?.length) return [];

  const info = uf.info(idxs, names, needle);
  const order = uf.sort(info, names, needle);

  return order.map((i) => haystack[info.idx[i]]);
};
