import { Box, Image, Text, tokens } from '@kluein/klue-ui';
import { useSelector } from 'react-redux';

import TEST_IDS from 'test-ids';

import type { CardType } from 'api/api.types';
import type { RootState } from 'store/store.types';

const KlueCardInfoBoards = ({ card }: { card: CardType }) => {
  const cardRivalId = useSelector((state: RootState) => {
    return state.rivals.byProfileId.get(
      card?.board?.profileId?.toString() || '',
    );
  });
  const cardRival = useSelector((state: RootState) => {
    return state.rivals.byId.get(cardRivalId?.toString() || '');
  });

  return cardRival ? (
    <Box
      direction="row"
      align="center"
      data-test-id={TEST_IDS.klueCard.klueCardInfo.boards}
    >
      {cardRival?.iconUrl ? (
        <Box flex={false}>
          <Image
            alt={cardRival?.name}
            height={tokens.size.ComponentIconSize.medium}
            margin={{ right: tokens.spacing.xsmall }}
            src={cardRival?.iconUrl}
            width={tokens.size.ComponentIconSize.medium}
          />
        </Box>
      ) : null}
      <Text fontWeight="semibold" truncate>
        {cardRival?.name}
      </Text>
    </Box>
  ) : null;
};

export default KlueCardInfoBoards;
