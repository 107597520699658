import {
  Text,
  Box,
  Button,
  useResponsive,
  tokens,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { CountText } from 'components/common/count-text';
import { UserAvatar } from 'components/common/user-avatar';
import { useAuth } from 'contexts/auth';
import { APP_HELP_URL, APP_V1_BASEURL, WHATS_NEW_URL } from 'lib/urls';
import store from 'store';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { UserMenuLink } from './partials';

import type { Dispatch, RootState } from 'store/store.types';

type UserMenuProps = {
  onClose: VoidFunction;
  /* Used for easy mocking in Storybook. When provided, ignores fields in user object. */
  avatar?: {
    name: string;
    src: string;
  };
};

type linkProps = {
  href: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const UserMenu = ({ avatar, onClose }: UserMenuProps) => {
  const {
    user,
    isCurator,
    isAdmin,
    signOut,
    isReportsEnabled,
    isImpersonating,
    revertImpersonatingUser,
    isWinLossEnabled,
    isWinLossExclusive,
    isNewCuratorNavEnabled,
    isKnowledgeHubEnabled,
  } = useAuth();
  const navigate = useNavigate();
  const { maxWidth } = useResponsive();
  const { pathname } = useLocation();

  const notifications = useSelector<RootState, number>(
    store.select.auth.notifications,
  );
  const visibilityGroupId = useSelector(store.select.auth.visibilityGroupId);

  const feedLinkProps: linkProps = {
    href: `${APP_V1_BASEURL}/feed`,
  };
  const digestLinkProps: linkProps = {
    href: `${APP_V1_BASEURL}/digest/overview`,
  };
  if (visibilityGroupId !== null) {
    feedLinkProps.onClick = (e) => {
      dispatch.auth.setPreviewExitInfoDialog({
        destination: 'news feed',
        redirectUrl: feedLinkProps.href,
      });
      e.preventDefault();
    };
    digestLinkProps.onClick = (e) => {
      dispatch.auth.setPreviewExitInfoDialog({
        destination: 'digest',
        redirectUrl: digestLinkProps.href,
      });
      e.preventDefault();
    };
  }

  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  if (!user?.id) return null;

  const userCanImpersonate = user.canImpersonate;

  const handleSignOut = () => {
    signOut();
  };

  const renderSectionTitle = (title: string) => {
    return (
      <Text
        color={tokens.color.neutral.darkgrey.main}
        size="large"
        margin="none"
        lineHeight={tokens.lineHeight.regular}
        as="h4"
      >
        {title}
      </Text>
    );
  };

  const gotoRoute = (route: string) => {
    navigate(route);
    onClose();
  };

  const renderOverflowNavigation = () => (
    <Box flex={{ shrink: 0 }} pad={{ horizontal: 'xxlarge' }} gap="xsmall">
      {isCurator && (
        <UserMenuLink
          onClick={() => gotoRoute('/alerts')}
          active={pathname.startsWith('/alerts')}
          label={t('Common:alerts')}
        />
      )}

      {isCurator && (
        <UserMenuLink
          href={`${APP_V1_BASEURL}/monitors`}
          active={pathname.startsWith('/monitors')}
          label={t('Common:monitors')}
        />
      )}

      {isWinLossEnabled && (
        <UserMenuLink
          data-test-id={TEST_IDS.appNavBar.userMenu.winLossButton}
          data-tracking-id={TRACKING_IDS.winLoss.globalNav}
          onClick={() => gotoRoute('/win-loss')}
          active={pathname.startsWith('/win-loss')}
          label={t('Common:winLoss')}
        />
      )}

      <UserMenuLink {...feedLinkProps} label={t('Common:feed')} />

      <UserMenuLink {...digestLinkProps} label={t('Common:digest')} />
    </Box>
  );

  return (
    <Box fill data-test-id={TEST_IDS.appNavBar.userMenu.container}>
      <Box flex={{ grow: 1 }} basis="0" pad={{ top: 'xlarge' }}>
        <Box pad="xxlarge" flex={false}>
          <Box direction="row" gap="small" align="center">
            <Button variant="plain" href={`${APP_V1_BASEURL}/users/${user.id}`}>
              <UserAvatar userId={user?.id} size="large" {...avatar} />
            </Button>
            <Box>
              <Button
                variant="plain"
                href={`${APP_V1_BASEURL}/users/${user.id}`}
              >
                <Text
                  size="xxlarge"
                  fontWeight="semibold"
                  margin={{ vertical: '0' }}
                >
                  @{user.username}
                </Text>
              </Button>
              <Box direction="row" gap="small" align="start">
                {!isWinLossExclusive && (
                  <Button
                    variant="plain"
                    size="small"
                    href={`${APP_V1_BASEURL}/users/${user.id}`}
                  >
                    {t('Common:userMenu.editProfile')}
                  </Button>
                )}
                <Button
                  variant="plain"
                  size="small"
                  onClick={handleSignOut}
                  data-test-id={TEST_IDS.appNavBar.userMenu.signoutButton}
                >
                  {t('Common:userMenu.signOut')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box overflow={{ vertical: 'auto' }} pad={{ top: 'xxsmall' }}>
          {maxWidth.medium &&
            !isWinLossExclusive &&
            !isNewCuratorNavEnabled &&
            renderOverflowNavigation()}

          <Box
            flex={{ shrink: 0 }}
            margin={{ vertical: 'large', horizontal: 'xxlarge' }}
            border={{
              side: 'top',
              color: tokens.color.neutral.lightgrey.main,
            }}
          />

          <Box
            margin={{ top: 'xlarge' }}
            pad={{ horizontal: 'xxlarge' }}
            gap="xxlarge"
            flex={{ shrink: 0 }}
          >
            {!isWinLossExclusive && isCurator ? (
              <UserMenuLink
                label={t('Common:userMenu.notifications')}
                href={`${APP_V1_BASEURL}/notifications`}
                rightSlot={
                  !!notifications ? (
                    <CountText>{notifications}</CountText>
                  ) : undefined
                }
              />
            ) : null}

            {isCurator && (
              <Box gap="small">
                {renderSectionTitle(
                  isAdmin ? t('Common:userMenu.admin') : t('Common:curators'),
                )}
                {isKnowledgeHubEnabled && (
                  <UserMenuLink
                    onClick={() => gotoRoute('/knowledge-hub')}
                    active={pathname.startsWith('/knowledge-hub')}
                    label={t('Common:userMenu.knowledgeHub')}
                    data-test-id={
                      TEST_IDS.appNavBar.userMenu.knowledgeHubButton
                    }
                  />
                )}
                <UserMenuLink
                  label={t('Common:userMenu.manageUsers')}
                  href={`${APP_V1_BASEURL}/users`}
                />
                {!isWinLossExclusive && (
                  <UserMenuLink
                    label={t('Common:userMenu.companySettings')}
                    href={`${APP_V1_BASEURL}/settings`}
                  />
                )}
                {!isWinLossExclusive && isReportsEnabled && (
                  <UserMenuLink
                    data-test-id={TEST_IDS.appNavBar.userMenu.reportsButton}
                    onClick={() => gotoRoute('/reports')}
                    active={pathname.startsWith('/reports')}
                    label={t('Common:userMenu.reports')}
                  />
                )}
              </Box>
            )}

            <Box gap="small">
              {renderSectionTitle(t('Common:userMenu.tips'))}
              <UserMenuLink
                onClick={() => gotoRoute('/integrations')}
                active={pathname === '/integrations'}
                label={t('Common:userMenu.apps&Integrations')}
              />
              <UserMenuLink
                label={t('Common:userMenu.faqs&Guides')}
                href={APP_HELP_URL}
                isExternalLink
              />
              <UserMenuLink
                label={t('Common:userMenu.whatsNew')}
                href={WHATS_NEW_URL}
                isExternalLink
              />
            </Box>
          </Box>

          {(isImpersonating || userCanImpersonate) && (
            <Box
              margin={{ top: 'xlarge' }}
              pad={{ horizontal: 'xxlarge' }}
              gap="xxlarge"
              flex={{ shrink: 0 }}
            >
              <Box gap="small">
                {renderSectionTitle(t('Common:userMenu.klueStaff'))}
                {isImpersonating && (
                  <UserMenuLink
                    variant="primary"
                    size="medium"
                    label={t('Common:userMenu.revertTo')}
                    onClick={revertImpersonatingUser}
                    data-test-id={TEST_IDS.appNavBar.userMenu.revertImpersonate}
                  />
                )}

                {!isImpersonating && userCanImpersonate && (
                  <UserMenuLink
                    label={t('Common:userMenu.switchInstance')}
                    href={`${APP_V1_BASEURL}/impersonate`}
                    data-test-id={TEST_IDS.appNavBar.userMenu.switchInstance}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        margin={{ vertical: 'xlarge', horizontal: 'xxlarge' }}
        animation="fadeIn"
        direction="row"
      >
        <Button variant="plain" href="https://klue.com" target="_blank">
          <PhosphorIcon.KlueLogo
            size={tokens.iconSize.xlarge}
            color={tokens.color.secondary[800]}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default UserMenu;
