import type { S3UploadOptions } from '@kluein/react-s3-uploader/s3upload';

type getSignedUrlType = Exclude<S3UploadOptions['getSignedUrl'], undefined>;

const getSignedUrl: getSignedUrlType = async (
  ...args: Parameters<getSignedUrlType>
) => {
  const [file, callback] = args;
  const result = await fetch(
    `/api/uploads/post_url.json?objectName=${encodeURIComponent(
      file.name,
    )}&contentType=${encodeURIComponent(file.type)}&contentLengthLimit=0`,
    {
      method: 'GET',
    },
  );

  callback(await result.json());
};

export default getSignedUrl;
