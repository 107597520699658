import { Box, tokens } from '@kluein/klue-ui';

import { KlueCardInfo } from 'components/common/klueCard/partials';
import KlueCardInfoBoards from 'components/common/klueCard/partials/klue-card-info/KlueCardInfoBoards';
import TEST_IDS from 'test-ids';

import type { CardType } from 'api/api.types';

type CardEditorCardInfoProps = {
  card?: CardType;
};

export const CardEditorCardInfo: React.FC<CardEditorCardInfoProps> = ({
  card,
}) => {
  if (!card) return null;
  return (
    <Box
      align="center"
      background={tokens.color.neutral[200]}
      border={{ side: 'top' }}
      data-test-id={TEST_IDS.cardEditor.cardInfo}
      direction="row"
      fill="horizontal"
      gap="large"
      justify="between"
      pad={tokens.spacing.small}
      flex={{ shrink: 0 }}
    >
      <Box direction="row" align="center">
        <KlueCardInfoBoards card={card} />
      </Box>
      <Box flex={false}>
        <KlueCardInfo
          card={card}
          sourcesCount={card?.sourcesCount}
          hasCardSourcesIcon={false}
        />
      </Box>
    </Box>
  );
};
