import {
  Box,
  Button,
  Layer,
  tokens,
  PhosphorIcon,
  ThemeContext,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';

type AppSideMenuProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  position: 'left' | 'right';
  children?: React.ReactNode;
};
const AppSideMenu: React.FC<AppSideMenuProps> = ({
  isOpen,
  onClose,
  position,
  children,
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;
  return (
    <ThemeContext.Extend
      value={{
        layer: {
          extend: {
            zIndex: 11,
          },
        },
      }}
    >
      <Layer
        position={position}
        onClickOutside={onClose}
        style={{ cursor: 'default' }}
        full="vertical"
        onEsc={onClose}
        background={tokens.color.neutral.white.main}
        responsive={false}
        data-test-id={TEST_IDS.layout.appSideMenu.layer}
      >
        <Box
          style={{ position: 'absolute', top: 0, right: 0 }}
          margin={{ right: 'medium', top: 'small' }}
        >
          <Button
            data-test-id={TEST_IDS.layout.appSideMenu.closeButton}
            a11yTitle={t('Common:appSideMenu.closeMenu')}
            onClick={onClose}
            variant="plain"
            icon={<PhosphorIcon.X size={tokens.iconSize.small} />}
          />
        </Box>
        <Box fill="vertical" width="large">
          {children}
        </Box>
      </Layer>
    </ThemeContext.Extend>
  );
};
export default AppSideMenu;
