import { Box, Button, Modal, tokens, PhosphorIcon } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import Header from 'components/common/battlecard-editor/partials/Header';
import { CardPermissions } from 'pages/profile/partials/sideAction/bulkEdit';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

type KlueCardVisibilityGroupsModalProps = {
  visibleTo: number;
  onClose: () => void;
  onDone: () => void;
};

const KlueCardVisibilityGroupsModal = ({
  visibleTo,
  onClose,
  onDone,
}: KlueCardVisibilityGroupsModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal showCloseButton={false} onClose={onClose}>
      <Modal.Header background={tokens.color.primary.main}>
        <Header
          icon={<PhosphorIcon.Eye weight="regular" />}
          title={t('Card:permissions.actionTitle')}
          onClose={onClose}
        />
      </Modal.Header>
      <Modal.Content>
        <Box pad="small">
          <CardPermissions visibleToDefault={visibleTo} showTitle={false} />
        </Box>
        <Box
          pad="small"
          justify="end"
          align="end"
          focusIndicator={false}
          flex={{ shrink: 0 }}
        >
          <Button
            variant="flat-outlined-form"
            label={t('Card:editor.visibilityGroups.done')}
            onClick={onDone}
            data-test-id={TEST_IDS.klueCard.visibilityGroups.done}
            data-tracking-id={TRACKING_IDS.editor.visibilityGroups.done}
          />
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default KlueCardVisibilityGroupsModal;
