import isEqual from 'lodash/isEqual';
import { useState, useMemo, useCallback, useEffect } from 'react';

import type { KlueCardTagDataType } from 'api/api.types';

const useEditTags = (tags?: KlueCardTagDataType[]) => {
  const initialTags = useMemo(() => tags || [], [tags]);
  const [editedTags, setEditedTags] =
    useState<KlueCardTagDataType[]>(initialTags);

  useEffect(() => {
    setEditedTags(initialTags);
  }, [initialTags]);

  const addTag = useCallback((tag: KlueCardTagDataType) => {
    setEditedTags((prevTags) => {
      const index = prevTags.findIndex(
        (t) => t.name.localeCompare(tag.name) > 0,
      );
      return index === -1
        ? [...prevTags, tag]
        : [...prevTags.slice(0, index), tag, ...prevTags.slice(index)];
    });
  }, []);

  const removeTag = useCallback((tag: KlueCardTagDataType) => {
    setEditedTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
  }, []);

  const tagsDirty = useMemo(() => {
    return !isEqual(initialTags, editedTags);
  }, [initialTags, editedTags]);

  return {
    editedTags,
    tagsDirty,
    addTag,
    removeTag,
  };
};

export default useEditTags;
