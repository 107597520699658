import { useCallback, useEffect, useRef, useState } from 'react';

import type { CardType } from 'api/api.types';

const useTextInputAutofocus = ({ card }: { card?: CardType }) => {
  const [didSetInputRefCount, setDidSetInputRefCount] = useState(0);
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const setTextInputRef = useCallback((node: HTMLInputElement) => {
    textInputRef.current = node;
    if (node !== null) {
      setDidSetInputRefCount((prev) => prev + 1);
    }
  }, []);
  useEffect(() => {
    if (!didSetInputRefCount || !textInputRef?.current) {
      return;
    }

    if (!card?.id) {
      textInputRef.current.focus();
      textInputRef.current.select();
    }
  }, [didSetInputRefCount, card?.id]);

  return {
    setTextInputRef,
  };
};

export { useTextInputAutofocus };
