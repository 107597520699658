import {
  Anchor,
  Box,
  Text,
  tokens,
  fontWeight,
  letterSpacing,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { APP_V1_BASEURL } from 'lib/urls';

type NavBarLinkProps = {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  label?: string;
  onClick?: (params: any) => void;
  title?: string;
  to?: string;
  v1?: boolean;
};

const commonStyles = ({ theme }: any): string => `
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  text-overflow: ellipsis;
  transition: background-color 250ms ease-in-out, color 250ms 125ms ease-in-out;
  white-space: nowrap;

  &:link, &:hover, &:visited, &:active {
    &, span {
      background-color: transparent;
      text-decoration: none;
    }
  }

  span {
    color: ${tokens.color.neutral.black.main};
    font-weight: ${fontWeight.bold};
    letter-spacing: ${letterSpacing.small};
    text-transform: uppercase;
  }

  &:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
    span {
      border-radius: 2px;
      box-shadow: 0 0 2px 2px ${tokens.color.primary.main};
    }
  }

  .navBar-overflow-menu & {
    justify-content: end;
    margin-right: 20px;
  }

  .navBar-avatar:hover & {
    background-color: ${tokens.color.neutral.lightgrey.main}
  }

  .navBar-avatar & {
    min-width: 60px;
    display: flex;
    top: 0;
    justify-content: flex-end;
    width: 100%;
  }

  .navBar-overflow & {
    top: 0;
    &:hover{
      background-color: ${tokens.color.neutral.lightgrey.main};
    }
  }

  .navBar-home & span,
  .navBar-research & span,
  .navBar-composer & span {
    letter-spacing: normal;
    position: relative;
    top: -1px;
  }

  .navBar-home-icon &,
  .navBar-research-icon &,
  .navBar-composer-icon & {
    top:0;
    display:flex;
    &:hover{
      background-color: ${tokens.color.neutral.lightgrey.main};
    }
  }
`;

const StyledAnchor = styled(Anchor)`
  ${(props) => commonStyles(props)}
`;
const StyledLink = styled(Link)`
  ${(props) => commonStyles(props)}
`;
const StyledBox = styled(Box)`
  ${(props) => commonStyles(props)}
  padding: 0;
`;

const Wrapper = styled(Box)`
  & + & {
    border-left: none;
  }
  height: 100%;
  position: relative;
  margin-right: 20px;

  &.navBar-avatar,
  &.navBar-overflow {
    margin-right: 0;
  }

  span {
    display: flex;
  }

  &.navBar-home,
  &.navBar-home-icon {
    margin-left: 20px;
  }

  &:not(.navBar-avatar, .navBar-home-icon, .navBar-overflow) {
    &:hover:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${tokens.color.neutral.darkgrey.main};
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${tokens.color.neutral.black.main};
    left: 0;
    right: 0;
    bottom: 0;
  }

  .navBar-overflow-menu & {
    border: none;
    min-width: ${(props) => props.theme.global.size.medium};
    width: 100%;
    height: 44px;
    & > * {
      justify-content: end;
    }
  }
`;

const NavBarLink: React.FC<NavBarLinkProps> = ({
  active: isActive,
  children,
  className = '',
  label,
  onClick,
  title,
  to = '',
  v1 = false,
  ...rest
}) => {
  let link;
  let active = false;
  const { t } = useTranslation(['Common']);
  const { pathname } = useLocation();

  if (!to) {
    link = (
      <StyledBox
        className={isActive ? 'active' : ''}
        onClick={onClick}
        {...rest}
      >
        {label ?? children}
      </StyledBox>
    );
  } else {
    const href = v1 ? `${APP_V1_BASEURL}${to}` : to;
    active =
      isActive ||
      pathname === to ||
      ([pathname, to].reduce((acc, curr) => curr !== '/', false) &&
        pathname.startsWith(to));

    const contents = label && (
      <Text truncate size="medium" wordBreak="keep-all">
        {label}
      </Text>
    );

    link = v1 ? (
      <StyledAnchor
        a11yTitle={`${t('Common:navBar.openInLegacyApp', {
          title,
        })}`}
        className={active ? 'active' : ''}
        href={href}
        onClick={onClick}
        {...rest}
      >
        {contents}
        {children}
      </StyledAnchor>
    ) : (
      <StyledLink
        className={active ? 'active' : ''}
        onClick={onClick}
        to={href}
        aria-label={title}
        {...rest}
      >
        {contents ?? children}
      </StyledLink>
    );
  }

  return (
    <Wrapper className={`${className} ${active ? 'active' : ''}`}>
      {link}
    </Wrapper>
  );
};

export default NavBarLink;
