import { Box, Button, Heading, tokens, PhosphorIcon } from '@kluein/klue-ui';
import { useEffect } from 'react';

import TEST_IDS from 'test-ids';

type HeaderProps = {
  icon?: React.ReactNode;
  title: string;
  onClose: () => void;
};

const Header = ({ icon = null, title, onClose }: HeaderProps) => {
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [onClose]);
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      background={tokens.color.primary.main}
      fill="horizontal"
      gap="large"
    >
      <Box direction="row" gap="small" align="center">
        {icon}
        <Heading
          size="medium"
          margin="none"
          level="2"
          color={tokens.color.primary.contrastmain}
          data-test-id={TEST_IDS.battlecard.edit.header}
        >
          {title}
        </Heading>
      </Box>
      <Button
        variant="plain"
        onClick={onClose}
        icon={
          <PhosphorIcon.X color={tokens.color.primary.contrastmain} size={20} />
        }
      />
    </Box>
  );
};

export default Header;
