import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

import type { KlueEditor } from '@kluein/content-editor';

type CardBackup = {
  id?: number;
  title: string;
  content: any;
  lastModified: number;
};

export const BACKUP_KEY = 'card_editor_backup';
const DEBOUNCE_DELAY = 1000;

export const useCardBackup = (cardId?: number) => {
  const getBackup = useCallback(() => {
    const backup = localStorage.getItem(BACKUP_KEY);
    return backup ? (JSON.parse(backup) as CardBackup) : null;
  }, []);

  /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
  /* eslint-disable react-hooks/exhaustive-deps */
  const saveBackup = useCallback(
    debounce((title: string, editorInstance: KlueEditor | null) => {
      let content = '';

      if (editorInstance && !editorInstance.isEmpty) {
        content = editorInstance.getHTML();
      }
      const backup: CardBackup = {
        id: cardId,
        title,
        content,
        lastModified: Date.now(),
      };
      localStorage.setItem(BACKUP_KEY, JSON.stringify(backup));
    }, DEBOUNCE_DELAY),
    [cardId],
  );

  const clearBackup = useCallback(() => {
    localStorage.removeItem(BACKUP_KEY);
  }, []);

  const shouldRestoreBackup = useCallback(
    (backup: CardBackup | null) => {
      if (!backup) return false;

      if (cardId) {
        return backup.id === cardId;
      }

      return !backup.id;
    },
    [cardId],
  );

  // Handle tab close
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const backup = getBackup();
      if (backup) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [getBackup]);

  return {
    getBackup,
    saveBackup,
    clearBackup,
    shouldRestoreBackup,
  };
};
