import { tokens } from '@kluein/klue-ui';

import type { BattlecardsType, CardType } from 'api/api.types';

type SizeProps = {
  heading: {
    lineHeight: string;
    fontSize: string;
  };
  sidebar: {
    width: string;
  };
  battlecardContainer: {
    width: string;
  };
  battlecardContainerCollapsed: {
    width: string;
  };
  profileContainer: {
    width: string;
  };
  profileContainerCollapsed: {
    width: string;
  };
  navButton: {
    width: string;
  };
};

export const collapsedSidebarWidth = '12px';
export const pageMargin = '12px';
export const sidebarWidth = '245px';
export const sidebarWidthSmaller = '190px';

// Default styles used above largest breakpoint (>= 1750px)
const sizeProps: SizeProps = {
  heading: {
    lineHeight: tokens.lineHeight.dense,
    fontSize: tokens.fontSize.headingFont.medium,
  },
  sidebar: {
    width: sidebarWidth,
  },
  battlecardContainer: {
    width: `calc(100% - ${sidebarWidth} - ${pageMargin})`,
  },
  battlecardContainerCollapsed: {
    width: `calc(100% - ${collapsedSidebarWidth} - ${pageMargin})`,
  },
  profileContainer: {
    width: `calc(100% - ${sidebarWidth})`,
  },
  profileContainerCollapsed: {
    width: '100%',
  },
  navButton: {
    width: '221px',
  },
};

export function resizeScreen(screenSize: Record<string, boolean>) {
  // Below 412px
  if (!screenSize.xxsmall) {
    sizeProps.heading.lineHeight = tokens.lineHeight.xxdense;
    sizeProps.heading.fontSize = tokens.fontSize.bodyFont.medium;
    sizeProps.sidebar.width = sidebarWidth;
    sizeProps.battlecardContainer.width = `calc(100% - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${pageMargin})`;
    // Expanded sidebar is an overlay, does not affect content width
    sizeProps.profileContainer.width = '100%';
    sizeProps.profileContainerCollapsed.width = '100%';
    return sizeProps;
  }
  // Below 562px
  if (!screenSize.xsmall) {
    sizeProps.heading.lineHeight = tokens.lineHeight.dense;
    sizeProps.heading.fontSize = tokens.fontSize.headingFont.small;
    sizeProps.sidebar.width = sidebarWidth;
    sizeProps.battlecardContainer.width = `calc(100% - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${pageMargin})`;
    // Expanded sidebar is an overlay, does not affect content width
    sizeProps.profileContainer.width = '100%';
    sizeProps.profileContainerCollapsed.width = '100%';
    return sizeProps;
  }

  // Below 768px
  if (!screenSize.small) {
    sizeProps.heading.lineHeight = tokens.lineHeight.dense;
    sizeProps.heading.fontSize = tokens.fontSize.headingFont.medium;
    sizeProps.sidebar.width = sidebarWidth;
    sizeProps.navButton.width = '144px';
    sizeProps.battlecardContainer.width = `calc(100% - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${pageMargin})`;
    // Expanded sidebar is an overlay, does not affect content width
    sizeProps.profileContainer.width = '100%';
    sizeProps.profileContainerCollapsed.width = '100%';
    return sizeProps;
  }

  // Below 1024px
  if (!screenSize.medium) {
    sizeProps.heading.lineHeight = tokens.lineHeight.dense;
    sizeProps.heading.fontSize = tokens.fontSize.headingFont.large;
    sizeProps.sidebar.width = sidebarWidthSmaller;
    sizeProps.navButton.width = '176px';
    sizeProps.battlecardContainer.width = `calc(100% - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${pageMargin})`;
    // Expanded sidebar is an overlay, does not affect content width
    sizeProps.profileContainer.width = '100%';
    sizeProps.profileContainerCollapsed.width = '100%';
    return sizeProps;
  }

  // Below 1340px
  if (!screenSize.large) {
    sizeProps.heading.lineHeight = tokens.lineHeight.dense;
    sizeProps.heading.fontSize = tokens.fontSize.headingFont.large;
    sizeProps.sidebar.width = sidebarWidth;
    sizeProps.navButton.width = '176px';
    sizeProps.battlecardContainer.width = `calc(100% - ${sizeProps.sidebar.width} - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${collapsedSidebarWidth} - ${pageMargin})`;
    sizeProps.profileContainer.width = `calc(100% - ${sizeProps.sidebar.width})`;
    sizeProps.profileContainerCollapsed.width = `calc(100% - ${collapsedSidebarWidth})`;
    return sizeProps;
  }

  // Below 1750px
  if (!screenSize.xlarge) {
    sizeProps.heading.lineHeight = tokens.lineHeight.dense;
    sizeProps.heading.fontSize = tokens.fontSize.headingFont.large;
    sizeProps.sidebar.width = sidebarWidth;
    sizeProps.navButton.width = '221px';
    sizeProps.battlecardContainer.width = `calc(100% - ${sizeProps.sidebar.width} - ${pageMargin})`;
    sizeProps.battlecardContainerCollapsed.width = `calc(100% - ${collapsedSidebarWidth} - ${pageMargin})`;
    sizeProps.profileContainer.width = `calc(100% - ${sizeProps.sidebar.width})`;
    sizeProps.profileContainerCollapsed.width = `calc(100% - ${collapsedSidebarWidth})`;
    return sizeProps;
  }

  // Above 1750px
  return sizeProps;
}

export function getMostRecentActionDate(battlecard: Partial<BattlecardsType>): {
  date: Date;
  type: 'updated' | 'reviewed';
} {
  const updatedAt = new Date(battlecard.updatedAt || 0);
  const reviewedAt = new Date(battlecard.review?.reviewedAt || 0);

  const type =
    updatedAt.getTime() > reviewedAt.getTime() ? 'updated' : 'reviewed';

  return {
    date: type === 'updated' ? updatedAt : reviewedAt,
    type,
  };
}

export function getMostRecentCardActionDate(card: Partial<CardType>): {
  author?: number;
  date: Date;
  type: 'updated' | 'reviewed';
} {
  const updatedAt = new Date(card.updatedAt || 0);
  const reviewedAt = new Date(card.reviewer?.reviewedAt || 0);

  const type =
    updatedAt.getTime() > reviewedAt.getTime() ? 'updated' : 'reviewed';

  return {
    author: type === 'updated' ? card.author?.id : card.reviewer?.id,
    date: type === 'updated' ? updatedAt : reviewedAt,
    type,
  };
}
