import { ActionMenu, Box, PhosphorIcon } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { KlueCardMenu } from '../klue-card-menu';

import type { KlueCardActionMenuProps } from './KlueCardActionMenu.types';

function KlueCardActionMenu(props: KlueCardActionMenuProps) {
  const {
    sourcesCount,
    cardId,
    profileId,
    hideCardSources,
    hideOpenCard,
    rank,
  } = props;
  const { t } = useTranslation();

  return (
    <Box flex={false}>
      <KlueCardMenu
        sourcesCount={sourcesCount}
        cardId={cardId}
        profileId={profileId}
        isEditable
        trackingIds={{
          copyEmbed: TRACKING_IDS.global.card.actionMenu.copyEmbed,
          copyLink: TRACKING_IDS.global.card.actionMenu.copyLink,
          duplicateCard: TRACKING_IDS.global.card.actionMenu.duplicateCard,
          moveCard: TRACKING_IDS.global.card.actionMenu.moveCard,
          openCard: TRACKING_IDS.global.card.actionMenu.openCard,
          sources: TRACKING_IDS.global.card.actionMenu.sources,
          edit: TRACKING_IDS.global.card.actionMenu.edit,
          assignToBattlecards:
            TRACKING_IDS.global.card.actionMenu.assignToBattlecards,
          cardPermissions: TRACKING_IDS.global.card.actionMenu.cardPermissions,
        }}
        hideOpenCard={hideOpenCard}
        hideCardSources={hideCardSources}
        rank={rank}
      >
        {({ menuItems, handleClickMenuItem }) => {
          if (!menuItems.length) {
            return null;
          }
          return (
            <ActionMenu
              data-test-id={TEST_IDS.klueCard.header.menu}
              data-tracking-id={TRACKING_IDS.global.card.actionMenu.button}
              icon={<PhosphorIcon.DotsThreeOutlineVertical weight="fill" />}
              data={menuItems}
              a11yTitle={t('Card:header.menu.title')}
              tipText={t('Card:header.menu.title')}
              tipProps={{
                variant: 'small',
                color: 'dark',
              }}
              onClickItem={(item) => handleClickMenuItem(item, 'action_menu')}
            />
          );
        }}
      </KlueCardMenu>
    </Box>
  );
}

export default KlueCardActionMenu;
