import { createModel } from '@rematch/core';

import { fetchProfileById, fetchProfileRival } from 'api/endpoints/profile';
import {
  addVisibilityGroupIdToQuery,
  mergeMapValues,
  populateEntitiesFrom,
} from 'store/utils';
import * as profileUtils from 'store/utils/profile';

import type { ProfileType, RivalType, UserType } from 'api/api.types';
import type { FetchProfileByIdQueryType } from 'api/endpoints/profile.types';
import type { RootModel } from 'store/models';
import type { StateType as RivalsStateType } from 'store/models/rivals/rivals.model';
import type { StoreSelectors } from 'store/store.types';

export type StateType = {
  byId: Map<string, ProfileType>;
  allIds: Set<string>;
  currentProfileId?: number;
  currentRivalId?: number;
  isLoading: boolean;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  currentProfileId: undefined,
  currentRivalId: undefined,
  isLoading: false,
} as StateType;

type PopulatePayload = { profiles: Record<number, ProfileType> };

export const profiles = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state, { profiles }: PopulatePayload) => {
      return {
        ...state,
        byId: mergeMapValues(state.byId, profiles),
        allIds: new Set([...state.allIds, ...Object.keys(profiles)]),
      };
    },
    setCurrentProfileId: (state, id: number) => {
      return {
        ...state,
        currentProfileId: id,
      };
    },
    resetCurrentProfile: (state) => {
      return {
        ...state,
        currentProfileId: undefined,
        currentRivalId: undefined,
      };
    },
    setCurrentRivalId: (state, id: number) => {
      return {
        ...state,
        currentRivalId: id,
      };
    },
    setIsLoading: (state: StateType, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
    deleteBoards: (state: StateType, { boardIds }: { boardIds: number[] }) => {
      return {
        ...state,
        byId: new Map(
          [...state.byId].map(([key, value]) => {
            const boards = (value?.boards ?? []).filter(
              (boardId) => !boardIds.includes(boardId),
            );
            return [key, { ...value, boards }];
          }),
        ),
      };
    },
    deleteProfileById: (state, { id }: { id: string }) => {
      if (!state.byId.has(id)) {
        return state;
      }

      const byId = new Map(state.byId);
      byId.delete(id);

      return {
        ...state,
        byId,
      };
    },
    updateProfileById: (
      state,
      { id, changes }: { id: string; changes: Partial<ProfileType> },
    ) => {
      if (!state.byId.has(id)) {
        return state;
      }

      const updatedProfile = {
        ...state.byId.get(id),
        ...changes,
      } as ProfileType;

      return {
        ...state,
        byId: new Map(state.byId).set(id, updatedProfile),
      };
    },
  },
  selectors: (slice, createSelector) => ({
    isLoading() {
      return slice(({ isLoading }) => isLoading);
    },
    byId() {
      return slice(({ byId }) => byId);
    },
    currentProfileId() {
      return slice(({ currentProfileId }) => currentProfileId);
    },
    currentRivalId() {
      return slice(({ currentRivalId }) => currentRivalId);
    },
    currentProfile() {
      return createSelector(
        this.byId as any, // @TODO Can we fix TypeScript?
        this.currentProfileId as any,
        (
          byId: StateType['byId'],
          currentProfileId: StateType['currentProfileId'],
        ) => {
          if (currentProfileId) {
            return byId.get(currentProfileId.toString());
          }
        },
      );
    },
    currentProfileName() {
      return createSelector(
        this.currentProfile as any,
        (currentProfile: ProfileType | undefined) => currentProfile?.name,
      );
    },
    currentProfileBoardIds() {
      return createSelector(
        this.currentProfile as any,
        (currentProfile: ProfileType | undefined) => currentProfile?.boards,
      );
    },
    currentProfileRival(models: StoreSelectors) {
      return createSelector(
        this.currentRivalId as any,
        models.rivals.byId as any,
        (
          currentRivalId: StateType['currentRivalId'],
          rivalsById: RivalsStateType['byId'],
        ) => {
          if (currentRivalId !== undefined) {
            return rivalsById.get(currentRivalId.toString());
          }
        },
      );
    },
    currentCurators(models: StoreSelectors) {
      return createSelector(
        models.users.byId as any,
        this.currentProfile as any,
        (
          byId: Map<string, UserType>,
          currentRival: ProfileType | undefined,
        ) => {
          const curators = currentRival?.curators || [];
          if (curators.length !== 0) {
            return Array.from(byId.values()).filter((user) =>
              curators.includes(user.id),
            );
          }
          // return all the admins if there are no curators for the current profile
          return Array.from(byId.values()).filter((user) =>
            user?.roles?.includes('admin'),
          );
        },
      );
    },
    currentConsumers(models: StoreSelectors) {
      return createSelector(
        models.users.byId as any,
        this.currentProfile as any,
        (byId: Map<string, UserType>) => {
          return Array.from(byId.values());
        },
      );
    },
  }),
  effects: (dispatch) => {
    return {
      async fetchProfileById(id: number, rootState) {
        this.setIsLoading(true);
        this.setCurrentProfileId(id);

        const query: FetchProfileByIdQueryType = {};
        addVisibilityGroupIdToQuery(rootState, query);

        let data;
        try {
          const { data: profileData } = await fetchProfileById({
            path: {
              id,
            },
            query,
          });
          data = profileData;
        } catch (error) {
          this.setIsLoading(false);
          throw new Error(error as string);
        }

        // TODO: This should be refactored to adapt to https://github.com/kluein/frontend-v2/issues/290
        // we should receive the rival data within profile request
        const { data: profileRivalNormalizedData } = await fetchProfileRival({
          path: { id },
          query,
        });

        populateEntitiesFrom({
          entities: data.entities,
          dispatch,
        });

        const profile = Object.values(data.entities.profiles)[0];

        const profileRival = Object.values(
          profileRivalNormalizedData.entities.rivals,
        )[0] as RivalType;
        dispatch.rivals.populate({
          rivals: profileRivalNormalizedData.entities.rivals,
        });
        this.setCurrentRivalId(profileRival.id);

        this.setIsLoading(false);

        if (profile?.name) {
          dispatch.auth.updateLastProfileViewed({
            profileId: id,
            profileName: profile.name,
            rivalLogo: profileRival?.iconUrl || '',
          });
        }

        return data.result;
      },
      saveCardsDimensions(profileId: number, rootState) {
        profileUtils.saveCardsDimensions({ profileId, rootState });
      },
      refreshRivalCounts(
        { profileId, rivalId }: { profileId: number; rivalId: number },
        rootState,
      ) {
        const { cardsCount, battlecardsCount } =
          rootState.profiles.byId.get(profileId.toString()) || {};
        if (cardsCount || battlecardsCount) {
          dispatch.rivals.updateRivalById({
            id: rivalId.toString(),
            changes: {
              ...(typeof cardsCount === 'number' && { cardsCount }),
              ...(typeof battlecardsCount === 'number' && {
                battlecardsCount,
              }),
            },
          });
        }
      },
      async refreshProfileAndRivalCounts(
        {
          profileId,
        }: {
          profileId?: number;
        },
        rootState,
      ) {
        if (!profileId) {
          return;
        }
        const rivalId = rootState.profiles.byId.get(
          profileId.toString(),
        )?.rivalId;
        try {
          await dispatch.profiles.fetchProfileById(profileId);

          if (!rivalId) {
            return;
          }
          dispatch.profiles.refreshRivalCounts({ profileId, rivalId });
        } catch (error) {
          throw new Error(error as string);
        }
      },
    };
  },
});
