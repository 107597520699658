import { Box, Button, Text, tokens } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';

type CardEditorFailedLoadingProps = {
  onClose: () => void;
};

const CardEditorFailedLoading = ({ onClose }: CardEditorFailedLoadingProps) => {
  const { t } = useTranslation(['Card']);
  return (
    <Box
      background={tokens.color.neutral.white.main}
      gap="medium"
      justify="center"
      align="center"
      height="large"
      style={{ borderRadius: '5px' }}
      data-test-id={TEST_IDS.cardEditor.cardLoadFailed}
    >
      <Text size="large">{t(`Common:cardViewer.failed`)}</Text>

      <Button variant="flat-outlined" onClick={onClose} size="small">
        {t(`Common:actions.clickToClose`)}
      </Button>
    </Box>
  );
};

export default CardEditorFailedLoading;
