/* eslint-disable max-lines */
import {
  Box,
  Card,
  getSizeFromTheme,
  getEdgeSizeFromTheme,
  tokens,
  PhosphorIcon,
} from '@kluein/klue-ui';
import React, { lazy, Suspense, createRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAuth } from 'contexts/auth';
import { useAppUIContext } from 'contexts/ui';
import { BulkEditOption } from 'contexts/ui/bulk-edit-context/bulkEditContext.types';
import { useEmbedded } from 'contexts/ui/embedded-context';
import { useCardViewer } from 'hooks/use-card-viewer';
import sanitize from 'klue-html/sanitizer';
import {
  AnalyticsAction,
  AnalyticsCategory,
  useAnalytics,
} from 'lib/analytics';
import TEST_IDS from 'test-ids';

import CardSelector from './cardSelector';
import { useCardInteractionLog } from './hooks';
import useUpdateVisibilityGroups from './hooks/use-update-visibility-groups/useUpdateVisibilityGroups';
import { getStickyStyles } from './KlueCard.styles';
import { handleTableClicks, initTable } from './KlueCard.utils';
import {
  KlueCardContent,
  KlueCardHeader,
  KlueCardToolbar,
  KlueCardSentiment,
  KlueCardFeedbackModal,
  KlueCardInfo,
  KlueCardTags,
  KlueCardVisibilityGroups,
} from './partials';

import type { KlueCardProps } from './KlueCard.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

const KlueCardSources = lazy(
  () => import('./partials/klue-card-sources/KlueCardSources'),
);

const KLUE_CARD_SIZE = {
  normal: `${getSizeFromTheme('large')}px`,
  wide: `${getSizeFromTheme('xxlarge')}px`,
};
const KLUE_CARD_INTERNAL_GUTTER = getEdgeSizeFromTheme('large');
const KLUE_CARD_CUSTOM_MARGIN_CONTENT = `${KLUE_CARD_INTERNAL_GUTTER}px 0 ${getEdgeSizeFromTheme(
  'xxlarge',
)}px 0`;
export const KLUE_CARD_INTERNAL_SIZE = {
  normal: `${getSizeFromTheme('large') - KLUE_CARD_INTERNAL_GUTTER}px`,
  wide: `${getSizeFromTheme('xlarge') - KLUE_CARD_INTERNAL_GUTTER}px`,
};

const StyledTableScrollWrap = styled.div`
  width: 100%;
  min-width: fit-content;
  max-width: 100vw;
  resize: horizontal;
  table {
    margin: 0 !important;
  }
`;

const KlueCard = (props: KlueCardProps) => {
  const {
    cardData,
    rivalName = '',
    boardName = '',
    className = '',
    initialHeight = 'auto',
    isWide = false,
    overflow,
    featured = false,
    isBattlecardView = false,
    isSearchView = false,
    isCardViewerContent = false,
    hideMenus = false,
    showCardSentiment = false,
    fluid = false,
    bulkEditData = {
      isSelectable: false,
      isSelected: false,
    },
    onTitleHover,
    variant = 'default',
    onTitleClick,
    profileId,
    rivalId,
    padStyles,
    rank,
  } = props;

  // Image zoom handler
  const cardContainer = createRef<HTMLDivElement>();
  const { modal } = useAppUIContext();
  const isEmbedded = useEmbedded();
  const { t } = useTranslation();
  const {
    company,
    showCardInfo,
    isCardViewerUpdateEnabled,
    isNewCardRendererEnabled,
    isCardSentimentEnabled,
    isCurator,
    isNewCardEditorEnabled,
  } = useAuth();
  const { open: openCardViewer } = useCardViewer();
  const { id: cardId } = cardData ?? {};
  const cardName = cardData?.data?.name;
  const hasLoaded = Boolean(cardId);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const { onVisibilityGroupSelected } = useUpdateVisibilityGroups();

  const [showCardSources, setShowCardSources] = useState<boolean>(
    !!isCardViewerContent,
  );
  const [cardElevation, setCardElevation] = useState<string>('xsmall');

  const CARD_WIDTH = isWide ? KLUE_CARD_SIZE.wide : KLUE_CARD_SIZE.normal;
  const { isSelectable, isSelected, bulkEditOption, onToggleSelected } =
    bulkEditData;

  const isEditingCardPermissions =
    bulkEditOption === BulkEditOption.CardPermissions;

  const isTagVisible = !!company?.companyData.cardTagIsConsumerVisible;
  const showTags =
    isTagVisible && (!isSelectable || bulkEditOption === BulkEditOption.Tags);

  const { logEvent } = useAnalytics();
  const logCardInteraction = useCardInteractionLog();

  const logEventLabel = `${rivalName} : ${boardName} : ${cardName}`;

  useEffect(() => {
    const { current } = cardContainer;

    current &&
      current
        .querySelectorAll<HTMLDivElement>('.table-scrollwrap')
        .forEach(initTable);
  }, [cardContainer]);

  useEffect(() => {
    const { current } = cardContainer;

    const onMouseUp: EventListener = (_event) => {
      const selectedText = document.getSelection()?.toString().trim();
      if (selectedText && cardId) {
        logCardInteraction({
          action: AnalyticsAction.highlight,
          label: selectedText,
          cardId,
          rank,
        });
      }
    };
    current?.addEventListener('mouseup', onMouseUp);
    return () => current?.removeEventListener('mouseup', onMouseUp);
  }, [cardContainer, cardId, logCardInteraction, rank]);

  useEffect(() => {
    const { current } = cardContainer;

    const listener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const href = target.closest('a')?.getAttribute('href');

      if (!target || !current) return false;

      if (
        !current.contains(target as Node) &&
        !target.matches(`[data-card-id='${cardId}'].table-zoom-in a`)
      ) {
        return null;
      }

      if (href) {
        logEvent({
          event: {
            category: AnalyticsCategory.curatorAddedLinks,
            action: AnalyticsAction.view,
            label: `${rivalName} : ${boardName} : ${cardName} : ${href}`,
          },
        });

        if (cardId) {
          logCardInteraction({
            action: AnalyticsAction.link,
            label: href,
            cardId,
            rank,
          });
        }

        return;
      }

      if (target.matches('table, table *')) {
        if (target.nodeName === 'A') {
          // Links click should not trigger modal
          return;
        }

        return handleTableClicks({
          e: event,
          onOpenTable: ({ table }) => {
            if (table) {
              let zoomId = 'table0';
              const index = Array.from(
                table.closest('[data-card-id]')?.querySelectorAll('table') ||
                  [],
              ).indexOf(table);
              if (index >= 0) {
                zoomId = `table${index}`;
              }

              logEvent({
                event: {
                  category: AnalyticsCategory.zoom,
                  action: AnalyticsAction.view,
                  label: `${rivalName} : ${boardName} : ${cardName} : ${zoomId}`,
                },
              });

              if (cardId) {
                logCardInteraction({
                  action: AnalyticsAction.table,
                  label: zoomId,
                  cardId,
                  rank,
                });
              }
            }

            return modal.open({
              children: (
                <Box
                  className={`${className} card-static-html_body`.trim()}
                  margin={{ top: 'xsmall' }}
                >
                  <PhosphorIcon.X
                    style={{ alignSelf: 'end', cursor: 'pointer' }}
                    size={tokens.iconSize.xsmall}
                    onClick={modal.close}
                  />
                  <StyledTableScrollWrap
                    data-card-id={cardId}
                    className="table-scrollwrap table-zoom-in"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(table?.outerHTML ?? ''),
                    }}
                    onClick={(event: React.MouseEvent) => {
                      const targ = event?.target as HTMLAnchorElement;

                      targ.classList.contains('card-external-link') &&
                        targ.setAttribute('target', '_blank');
                      listener(event as unknown as MouseEvent);
                    }}
                  />
                </Box>
              ),
              className: 'klue-table-modal',
            });
          },
        });
      }
    };

    current && current.addEventListener('click', listener);

    return () => {
      current && current.removeEventListener('click', listener);
    };
  }, [
    cardContainer,
    cardId,
    cardName,
    boardName,
    rivalName,
    className,
    modal,
    logEvent,
    logCardInteraction,
    rank,
  ]);

  const toggleCardSourcesBox = () => {
    if (isCardViewerUpdateEnabled && cardData?.id) {
      openCardViewer(cardData.id, { scrollToSources: true, rank });
    } else {
      setShowCardSources((s) => !s);
    }
  };

  const handleVisibilityGroupSelected = (
    cardPermissionCardsData?: GetCardPermissionCardsDataResult,
  ) => {
    onVisibilityGroupSelected?.(cardId, cardPermissionCardsData);
  };

  const propsToOverride =
    featured && !isCardViewerUpdateEnabled
      ? {
          elevation: 'none',
          pad: 'xxxlarge',
        }
      : {};

  const cardViewerContentOverride = isCardViewerContent
    ? {
        elevation: 'none',
        pad: 'none',
        border: false,
      }
    : {};

  const sticky =
    !hideMenus && isCardViewerUpdateEnabled && !isCardViewerContent ? (
      <KlueCardToolbar
        sourcesCount={cardData?.sourcesCount || 0}
        cardId={cardId || 0}
        profileId={profileId || 0}
        onFeedbackClick={setFeedbackModal}
        rank={rank}
      />
    ) : null;

  const displayVisibilityGroups =
    !isSearchView &&
    !isCardViewerContent &&
    bulkEditOption !== BulkEditOption.Tags &&
    cardData &&
    ((isCurator && isNewCardEditorEnabled) ||
      (isSelectable && isEditingCardPermissions));

  const displayCardInfo =
    !isSearchView &&
    (showCardInfo || !!bulkEditOption) &&
    !isCardViewerContent &&
    cardData &&
    !cardData?.data?.hasDynamicBlocks;

  const { isDraft, allAccess, visibilityGroups } = cardData || {};

  return (
    <Card
      data-card-id={cardId}
      data-test-id={TEST_IDS.klueCard.container}
      border={
        isSearchView ? false : { color: tokens.color.neutral.lightgrey.main }
      }
      style={{ position: 'relative' }}
      width={!fluid && !isBattlecardView ? CARD_WIDTH : undefined}
      fill={isBattlecardView}
      overflow={overflow}
      elevation={cardElevation}
      //PS: DON'T REMOVE this is used to consume card-static-html.css from v1
      className={`${className} card-static-html`.trim()}
      ref={cardContainer}
      stickyBuffer={isSearchView ? 0 : 73}
      stickyComponent={sticky}
      stickyContainerCustomStyles={getStickyStyles({ variant })}
      {...propsToOverride}
      {...cardViewerContentOverride}
      pad={padStyles}
    >
      {isSelectable && (
        <Box margin={{ bottom: 'xlarge' }}>
          <CardSelector
            format="card"
            id={cardId}
            isSelected={isSelected}
            onToggleSelected={onToggleSelected}
          />
        </Box>
      )}
      <KlueCardHeader
        {...props}
        onTitleHover={(isHover) => {
          if (isSearchView) {
            onTitleHover?.(isHover);
          } else {
            setCardElevation(() => (isHover ? 'large' : 'xsmall'));
          }
        }}
        onTitleClick={onTitleClick}
        hideMenus={hideMenus}
        rank={rank}
      />
      <KlueCardContent
        hasLoaded={hasLoaded}
        isCardViewerContent={isCardViewerContent}
        isNewCardRendererEnabled={isNewCardRendererEnabled}
        cardData={cardData?.data}
        initialHeight={initialHeight}
        cardId={cardId}
        logEvent={logEvent}
        rank={rank}
        logCardInteraction={logCardInteraction}
        logEventLabel={logEventLabel}
        KLUE_CARD_CUSTOM_MARGIN_CONTENT={KLUE_CARD_CUSTOM_MARGIN_CONTENT}
        KLUE_CARD_INTERNAL_GUTTER={KLUE_CARD_INTERNAL_GUTTER}
      />

      {isCardSentimentEnabled && showCardSentiment && cardId && !isCurator && (
        <KlueCardSentiment cardId={cardId} rank={rank} />
      )}

      {hasLoaded && (
        <>
          {showTags && (
            <KlueCardTags
              tags={cardData?.tags}
              t={t}
              analytics={{
                category:
                  (isBattlecardView && AnalyticsCategory.battlecard) ||
                  (isSearchView && AnalyticsCategory.search) ||
                  AnalyticsCategory.profile,
              }}
              isCardViewerContent={isCardViewerContent}
              cardId={cardId}
              shouldOpenInNewTab={isEmbedded}
              rank={rank}
            />
          )}

          {displayVisibilityGroups || displayCardInfo ? (
            <Card.Footer
              pad={isBattlecardView ? { bottom: 'small' } : undefined}
            >
              <Box direction="row" align="center" gap="small">
                {displayVisibilityGroups && (
                  <KlueCardVisibilityGroups
                    cardId={cardId}
                    flex={{ shrink: 0 }}
                    isDraft={isDraft}
                    visibilityGroups={visibilityGroups}
                    allAccess={allAccess}
                    minified={true}
                    displayInModal={true}
                    onVisibilityGroupSelected={
                      !!bulkEditOption
                        ? undefined
                        : handleVisibilityGroupSelected
                    }
                  />
                )}
                {displayCardInfo && (
                  <KlueCardInfo
                    card={cardData}
                    sourcesCount={cardData?.sourcesCount}
                    hasCardSourcesIcon={!isSelectable && !isBattlecardView}
                    onCardSourcesClick={toggleCardSourcesBox}
                    shouldOpenInNewTab={isEmbedded}
                  />
                )}
              </Box>
            </Card.Footer>
          ) : null}
        </>
      )}

      {cardId && showCardSources && (
        <Suspense fallback={<div />}>
          <KlueCardSources
            cardId={cardId}
            width={CARD_WIDTH}
            onClose={toggleCardSourcesBox}
            sourcesCount={cardData?.sourcesCount || 0}
            isCardViewerContent={isCardViewerContent}
          />
        </Suspense>
      )}
      {cardId && cardData && (
        <KlueCardFeedbackModal
          isFeedbackModalOpen={feedbackModal}
          onClickModal={setFeedbackModal}
          cardId={cardId}
          cardName={cardName}
          rivalId={rivalId}
          profileId={cardData.board?.profileId || profileId}
        />
      )}
    </Card>
  );
};

export default KlueCard;
