import type { CardEditorProps } from './CardEditor';
import type { JSONContent } from '@kluein/content-editor';
import type { CardType } from 'api/api.types';

type BlockWithValidation = {
  type: string;
  attrs?: {
    blockValid?: boolean;
    name?: string;
  };
  content: JSONContent;
};

export function validateEditorContent(content: JSONContent): string | null {
  const validateBlocks = (blocks: BlockWithValidation[]): string | null => {
    for (const block of blocks) {
      if (block.attrs?.blockValid === false) {
        return `Invalid content block: ${block.attrs.name || block.type}`;
      }

      if (Array.isArray(block.content)) {
        const childError = validateBlocks(
          block.content as BlockWithValidation[],
        );
        if (childError) {
          return childError;
        }
      }
    }
    return null;
  };

  if (Array.isArray(content.content)) {
    return validateBlocks(content.content as BlockWithValidation[]);
  }

  return null;
}

export const parseBlocks = (cardData: CardType['data']): string => {
  if (!cardData.textJson) {
    return cardData?.textHtml || '';
  }

  return cardData.textJson
    .map((item) => {
      if (item.type === 'html') {
        return item.data;
      }
      return '';
    })
    .join('');
};

export const areCardEditorPropsEqual = (
  prevProps: CardEditorProps,
  nextProps: CardEditorProps,
) => {
  if (
    prevProps.isLoading !== nextProps.isLoading ||
    prevProps.failedLoading !== nextProps.failedLoading
  ) {
    return false;
  }
  return prevProps.card?.id === nextProps.card?.id;
};
