import { Box, tokens, Skeleton } from '@kluein/klue-ui';

import TEST_IDS from 'test-ids';

const CardEditorLoading = () => {
  return (
    <Box
      background={tokens.color.neutral.white.main}
      pad={{ vertical: 'large', horizontal: 'xxlarge' }}
      data-test-id={TEST_IDS.cardEditor.cardLoading}
    >
      <Skeleton />
    </Box>
  );
};

export default CardEditorLoading;
