import {
  type BadgeColors,
  type FontWeightType,
  theme,
  tokens,
} from '@kluein/klue-ui';

import type { SelectedFilterRibbonObject, TextTransformValues } from './types';

export const metadataStyleMatrix: Record<
  string,
  {
    background: string;
    foreground: string;
  }
> = {
  winloss_positive: {
    background: tokens.color.secondary[300],
    foreground: tokens.color.secondary[800],
  },
  winloss_negative: {
    background: tokens.color.tertiary[300],
    foreground: tokens.color.tertiary.dark,
  },
  winloss_other: {
    background: tokens.color.neutral[400],
    foreground: tokens.color.neutral[900],
  },
  churn_negative: {
    background: tokens.color.sentiment.mixed[200],
    foreground: tokens.color['tertiary-2'].dark,
  },
  churn_other: {
    background: tokens.color.neutral[400],
    foreground: tokens.color.neutral[900],
  },
  churn_positive: {
    background: tokens.color.secondary[300],
    foreground: tokens.color.secondary[800],
  },
};

export const fontWeightStyles: Record<string, FontWeightType> = {
  'interview-date': 'semibold',
  'interview-name': 'semibold',
  'interviewee-name': 'semibold',
  default: 'medium',
};

export const textTransformStyles: Record<string, TextTransformValues> = {
  'company-name': 'uppercase',
  default: 'none',
};

export const improvedMetadataStyleMatrix: Record<
  string,
  {
    badgeType: BadgeColors;
  }
> = {
  winloss_positive: {
    badgeType: 'purple',
  },
  winloss_negative: {
    badgeType: 'teal',
  },
  winloss_other: {
    badgeType: 'grey',
  },
  churn_negative: {
    badgeType: 'orange',
  },
  churn_other: {
    badgeType: 'grey',
  },
  churn_positive: {
    badgeType: 'blue',
  },
};

export const featuredContentTagNames = [
  'Featured quotes',
  'Featured Quotes',
  'Featured Quote',
];

export const Slider = {
  URL_QUERY_PARAM: 'slider',
  Types: {
    COMPETITOR_HIGHLIGHTS: 'COMPETITOR_HIGHLIGHTS',
    DEAL: 'DEAL',
    HIGHLIGHTS: 'HIGHLIGHTS',
    PROGRAM_OPPORTUNITY_DETAILS: 'PROGRAM_OPPORTUNITY_DETAILS',
    UNKNOWN: 'UNKNOWN',
  },
};

export const SIDE_MENU_WIDTH = 188;

export const WIN_LOSS_BREAKPOINTS = {
  smartphone: {
    min: 0,
    max: 559,
  },
  tablet: {
    min: 560,
    max: 847,
  },
  desktop: {
    min: 848,
  },
};

export const WIN_LOSS_BREAKPOINTS_WITH_SIDE_MENU = {
  smartphone: {
    min: WIN_LOSS_BREAKPOINTS.tablet.min,
    max: WIN_LOSS_BREAKPOINTS.tablet.max,
  },
  tablet: {
    min: WIN_LOSS_BREAKPOINTS.tablet.min + SIDE_MENU_WIDTH,
    max: WIN_LOSS_BREAKPOINTS.tablet.max! + SIDE_MENU_WIDTH,
  },
  desktop: {
    min: WIN_LOSS_BREAKPOINTS.desktop.min + SIDE_MENU_WIDTH,
  },
};

export const MasonryBreakpoints = {
  default: {
    [WIN_LOSS_BREAKPOINTS.smartphone.min]: 1,
    [WIN_LOSS_BREAKPOINTS.tablet.min]: 2,
    [WIN_LOSS_BREAKPOINTS.desktop.min]: 3,
  },
  themes: {
    default: { 0: 1, 996: 2 },
    withSideMenuOffset: { 0: 1, [996 + SIDE_MENU_WIDTH]: 2 },
  },
  withSideMenuOffset: {
    [WIN_LOSS_BREAKPOINTS_WITH_SIDE_MENU.smartphone.min]: 1,
    [WIN_LOSS_BREAKPOINTS_WITH_SIDE_MENU.tablet.min]: 2,
    [WIN_LOSS_BREAKPOINTS_WITH_SIDE_MENU.desktop.min]: 3,
  },
};
export const MASONRY_GUTTER_XSMALL = theme.global?.edgeSize?.xsmall;
export const MASONRY_GUTTER_MEDIUM = theme.global?.edgeSize?.medium;

export const FILTER_BAR_FIELD_ALL_SELECTED = 'all-selected';

export const MAX_HIGHLIGHT_ITEMS = 1000;

export const DATE_RANGE_URL_FILTER_PARAM =
  '00000000-0000-0000-0000-000000000000';

export const URL_DYNAMIC_FILTERS: SelectedFilterRibbonObject = {
  [DATE_RANGE_URL_FILTER_PARAM]: [],
};

export const URL_STATIC_FILTERS: SelectedFilterRibbonObject = {
  programs: [],
  topics: [],
  tags: [],
};

export const BAR_COLOR_MATRIX = {
  positive: tokens.color.secondary['700'],
  negative: tokens.color.tertiary['800'],
  neutral: tokens.color.neutral['800'],
};

/**
 * This constant controls how much time in miliseconds
 * does the highlight items wait until the click
 * behavior works.
 */
export const HIGHLIGHTS_CLICK_DELAY = 250;

/**
 * The number of bars to be displayed
 * in each of win-loss charts
 */
export const MAX_BAR_CHARTS = 5;

/**
 * The excluded pattern from the create card modal board lane select list
 */
export const SCRATCHPAD = 'scratchpad';

/**
 * The URL parameter to store the current page number
 */
export const URL_PAGE_NUMBER_PARAM = 'page';

/**
 * The maximum number of items displayed
 * in the opportunities table
 */
export const MAX_OPPORTUNITIES_PER_PAGE = 30;

/**
 * This is the WinLoss app base URL
 */
export const WIN_LOSS_URL_BASE = '/win-loss';

/**
 * This is the WinLoss app base URL
 * for public pages
 */
export const WIN_LOSS_PUBLIC_URL_BASE = `${WIN_LOSS_URL_BASE}/p`;

export const ANALYTICS_REPORT_IDS = {
  THREAT_ANALYSIS: 'threat-analysis',
  DATA_HYGIENE: 'data-hygiene',
};
