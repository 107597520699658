import { PhosphorIcon, useNotifier, NotifierType } from '@kluein/klue-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useUpdateCardMutation } from 'store/api/cards';

import type { UpdateCardsType } from 'api/api.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

type UpdateVisibilityGroupsArgs = {
  id: number;
  isDraft: boolean;
  allAccess: boolean;
  visibilityGroups?: number[];
};

const useUpdateVisibilityGroups = () => {
  const [updateCard] = useUpdateCardMutation();
  const dispatch = useDispatch();
  const { notify } = useNotifier();
  const { t } = useTranslation();

  const updateCardVisibilityGroups = useCallback(
    async ({
      id,
      isDraft,
      allAccess,
      visibilityGroups,
    }: UpdateVisibilityGroupsArgs) => {
      const cardsParam: UpdateCardsType = {
        cardIds: [id],
        isDraft,
        allAccess,
      };

      if (visibilityGroups) {
        cardsParam.visibilityGroups = visibilityGroups;
      }

      const resultArray = await updateCard(cardsParam).unwrap();

      if (!resultArray?.length) {
        throw new Error('Failed to update card');
      }

      const updatedCard = { ...resultArray[0] };
      // need to explicity copy these since they are frozen
      if (updatedCard.visibilityGroups) {
        updatedCard.visibilityGroups = [...updatedCard.visibilityGroups];
      }

      dispatch.cards.updateAndParseCardsData({ updatedCards: [updatedCard] });
    },
    [updateCard, dispatch],
  );

  const handleVisibilityGroupSelected = useCallback(
    async (
      cardId?: number,
      cardPermissionCardsData?: GetCardPermissionCardsDataResult,
    ) => {
      if (!cardId || !cardPermissionCardsData) return;

      const { isDraft, visibilityGroups, allAccess } =
        cardPermissionCardsData.find(({ id }) => id === cardId) || {
          isDraft: true,
          visibilityGroups: [],
          allAccess: false,
        };

      const visibilityGroupIds = visibilityGroups?.map(({ id }) => id);

      try {
        await updateCardVisibilityGroups({
          id: cardId,
          isDraft: isDraft ?? false,
          visibilityGroups: visibilityGroupIds,
          allAccess: allAccess ?? false,
        });
      } catch (error) {
        notify({
          type: NotifierType.ERROR,
          message: t('Card:permissions.cardUpdateFailed'),
          icon: <PhosphorIcon.WarningCircle />,
        });
      }
    },
    [updateCardVisibilityGroups, t, notify],
  );

  return { onVisibilityGroupSelected: handleVisibilityGroupSelected };
};

export default useUpdateVisibilityGroups;
