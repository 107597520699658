import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import store from 'store';
import { useUpdateBattlecardMutation } from 'store/api/battlecards';

import type { BattlecardsType } from 'api/api.types';
import type { Dispatch } from 'store/store.types';

type CardBattlecardsProps = {
  id: number;
  profileId?: number;
};

const getCheckedMapFromBattlecards = (
  battlecards: (Partial<BattlecardsType> & { id: number })[],
  id: number,
) => {
  const checkedMap = new Map();
  battlecards.forEach((battlecard) => {
    checkedMap.set(battlecard.id, battlecard.cards?.desktop.includes(id));
  });
  return checkedMap;
};

const getCountsMapFromBattlecards = (
  battlecards: (Partial<BattlecardsType> & { id: number })[],
) => {
  const countsMap = new Map();
  battlecards.forEach((battlecard) => {
    countsMap.set(battlecard.id, battlecard.cards?.desktop.length ?? 0);
  });
  return countsMap;
};

const bcSort = (a: BattlecardsType, b: BattlecardsType) => {
  return parseFloat(a.viewOrder) - parseFloat(b.viewOrder);
};

const useCardBattlecards = ({ id, profileId }: CardBattlecardsProps) => {
  const [updateBattlecard] = useUpdateBattlecardMutation();
  const dispatch = useDispatch<Dispatch>();
  const {
    profiles: { fetchProfileById },
  } = useDispatch<Dispatch>();
  const { currentBattlecards } = useSelector(
    store.select(({ battlecards }) => ({
      currentBattlecards: battlecards.currentBattlecards,
    })),
  );
  const [battlecards, setBattlecards] = useState<
    (Partial<BattlecardsType> & { id: number })[]
  >(currentBattlecards?.sort(bcSort));
  const [saving, setSaving] = useState(false);

  const [originalChecked, setOriginalChecked] =
    useState<Map<number, boolean>>();
  const [checked, setChecked] = useState<Map<number, boolean>>(() => {
    const checkMap = getCheckedMapFromBattlecards(battlecards, id);
    setOriginalChecked(checkMap);
    return checkMap;
  });
  const [counts, setCounts] = useState<Map<number, number>>(
    getCountsMapFromBattlecards(battlecards),
  );

  useEffect(() => {
    const sortedBattlecards = currentBattlecards?.sort(bcSort);
    setBattlecards(sortedBattlecards);
    const checkMap = getCheckedMapFromBattlecards(sortedBattlecards, id);
    setChecked(checkMap);
    setOriginalChecked(checkMap);
    setCounts(getCountsMapFromBattlecards(sortedBattlecards));
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentBattlecards]);

  const toggleChecked = useCallback(
    (battlecardId: number) => {
      const currentlyChecked = checked.get(battlecardId);

      setChecked((prev) => {
        const newChecked = new Map(prev);
        newChecked.set(battlecardId, !currentlyChecked);
        return newChecked;
      });

      setCounts((prevCounts) => {
        const currentCount = prevCounts.get(battlecardId) ?? 0;
        const newCount = currentlyChecked ? currentCount - 1 : currentCount + 1;
        const newCounts = new Map(prevCounts);
        newCounts.set(battlecardId, newCount);
        return newCounts;
      });
    },
    [checked, setChecked, setCounts],
  );

  const handleSave = useCallback(async () => {
    if (!profileId) {
      return;
    }

    setSaving(true);
    const toggledBattlecards = battlecards.reduce((acc, battlecard) => {
      const checkedValue = checked.get(battlecard.id);
      const originalCheckedValue = originalChecked?.get(battlecard.id);

      // don't update if the value hasn't changed
      if (checkedValue === originalCheckedValue) {
        return acc;
      }

      const cards = battlecard.cards?.desktop ?? [];
      if (checkedValue) {
        cards.push(id);
      } else {
        const index = cards.indexOf(id);
        if (index > -1) {
          cards.splice(index, 1);
        }
      }

      acc[battlecard.id] = cards;

      return acc;
    }, {} as Record<number, number[]>);

    try {
      const promises: Promise<any>[] = [];
      Object.entries(toggledBattlecards).forEach(([battlecardId, cards]) => {
        promises.push(
          updateBattlecard({
            id: parseInt(battlecardId),
            cardData: { desktop: cards },
          }),
        );
      });

      const results = await Promise.allSettled(promises);
      let errorCount = 0;

      results.forEach((promise, index) => {
        if (promise.status === 'fulfilled') {
          const result = promise.value;
          if (result) {
            const updatedBattlecard = { ...result, profile: profileId };
            dispatch.battlecards.update(updatedBattlecard);
          }
        } else {
          errorCount++;
        }
      });
      await fetchProfileById(profileId);

      if (errorCount) {
        return Promise.reject(new Error('Failed to save battlecards'));
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setSaving(false);
    }
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [battlecards, checked, originalChecked, updateBattlecard]);

  return useMemo(
    () => ({
      battlecards,
      checked,
      toggleChecked,
      counts,
      save: handleSave,
      saving,
    }),
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [battlecards, checked, toggleChecked, counts],
  );
};

export default useCardBattlecards;
