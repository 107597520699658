import { matchPath } from 'react-router-dom';

export const getCampaignContentFromUrl = (url = '') => {
  if (
    matchPath({ path: '/profile/:profileId/battlecard/view' }, url) ||
    matchPath({ path: '/profile/:profileId/battlecard/embed' }, url)
  ) {
    return 'Battlecard';
  }

  if (matchPath({ path: '/profile/:profileId/battlecard/edit' }, url)) {
    return 'Board';
  }

  if (
    matchPath({ path: '/profile/:profileId/edit/card/:cardId' }, url) ||
    matchPath({ path: '/card/embed/:cardId' }, url)
  ) {
    return 'Card';
  }

  if (matchPath({ path: '/dashboard' }, url)) {
    return 'Dashboard';
  }

  return 'unknown';
};

export const debugSnowplow = (msg: string, opts?: any) => {
  // eslint-disable-next-line no-console
  (opts?.logger ?? console.log)(
    `%c[🌨  Snowplow Analytics]%c ${msg}`,
    'color: snow; font-weight: bold;',
    'color: unset; font-weight: normal;',
    opts?.data,
  );
};

export const csvToArray = (
  csv: string | string[] | number | number[] = '',
): Array<number | string | null> | undefined => {
  if (!Boolean(csv) || !String(csv)) {
    return [];
  }

  return String(csv)
    .split(',')
    .map((el: string) => {
      if (/^\d*$/.test(el)) {
        return parseInt(el, 10);
      }
      if (/^null|undefined$/i.test(el)) {
        return null;
      }

      return el;
    });
};

export const SNOWPLOW_SCHEMAS: Record<string, string> = {
  ask_klue_interaction: 'iglu:com.klue/ask_klue_interaction/jsonschema/1-0-8',
  ask_klue_suggest: 'iglu:com.klue/ask_klue_suggest/jsonschema/1-0-0',
  battlecard: 'iglu:com.klue/battlecard/jsonschema/1-0-1',
  board: 'iglu:com.klue/board/jsonschema/2-0-3',
  lane: 'iglu:com.klue/lane/jsonschema/1-0-0',
  boardfilter: 'iglu:com.klue/boardfilter/jsonschema/1-0-0',
  comment: 'iglu:com.klue/comment/jsonschema/1-0-0',
  company: 'iglu:com.klue/company/jsonschema/1-0-0',
  dashboard_tile_action: 'iglu:com.klue/dashboard_tile_action/jsonschema/1-0-0',
  impression: 'iglu:com.klue/impression/jsonschema/1-0-5',
  intel_action: 'iglu:com.klue/intel_action/jsonschema/1-0-6',
  intel: 'iglu:com.klue/intel/jsonschema/1-0-2',
  intelfilter: 'iglu:com.klue/intelfilter/jsonschema/1-0-6',
  tag: 'iglu:com.klue/tag/jsonschema/1-0-0',
  user: 'iglu:com.klue/user/jsonschema/1-0-0',
  card: 'iglu:com.klue/card/jsonschema/1-0-4',
  card_103: 'iglu:com.klue/card/jsonschema/1-0-3',
  card_interaction: 'iglu:com.klue/card_interaction/jsonschema/1-0-11',
  insights_action: 'iglu:com.klue/insights_action/jsonschema/1-0-7',
  search_action: 'iglu:com.klue/search_action/jsonschema/1-0-3',
  supported_rival: 'iglu:com.klue/supported_rival/jsonschema/1-0-0',
  reviewfilter: 'iglu:com.klue/reviewfilter/jsonschema/1-0-2',
  aspect: 'iglu:com.klue/aspect/jsonschema/1-0-0',
  review: 'iglu:com.klue/review/jsonschema/1-0-0',
  insight: 'iglu:com.klue/insight/jsonschema/1-0-1',
  integration: 'iglu:com.klue/integration/jsonschema/1-0-0',
  integration_card_action:
    'iglu:com.klue/integration_card_action/jsonschema/1-0-1',
  data_sync_configuration_complete:
    'iglu:com.klue/data_sync_configuration_complete/jsonschema/1-0-0',
  data_sync_auth_modified:
    'iglu:com.klue/data_sync_auth_modified/jsonschema/1-0-0',
  insightfilter: 'iglu:com.klue/insightfilter/jsonschema/1-0-0',
  research_module_action:
    'iglu:com.klue/research_module_action/jsonschema/1-0-0',
  composer_card: 'iglu:com.klue/composer_card/jsonschema/1-0-0',
  composer_card_action: 'iglu:com.klue/composer_card_action/jsonschema/1-0-1',
  call_data_sync_auth_modified:
    'iglu:com.klue/call_data_sync_auth_modified/jsonschema/1-0-0',
  call_data_sync_configuration_complete:
    'iglu:com.klue/call_data_sync_configuration_complete/jsonschema/1-0-1',
  call_data_sync_toggled:
    'iglu:com.klue/call_data_sync_toggled/jsonschema/1-0-0',
  unleash_feature_flag: 'iglu:com.klue/unleash_feature_flag/jsonschema/1-0-0',
  competitive_dashboard_action:
    'iglu:com.klue/competitive_dashboard_action/jsonschema/1-0-0',
  landing_page_action: 'iglu:com.klue/landing_page_action/jsonschema/1-0-2',
  alerts_notification_action:
    'iglu:com.klue/alerts_notification_action/jsonschema/1-0-0',
};
