import store from 'store';

import { api } from '../api';

import type {
  UpdateBattlecardsType,
  CreateBattlecardPayloadType,
} from 'api/api.types';

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateBattlecard: build.mutation<any, UpdateBattlecardsType>({
      query: (battlecard) => {
        const { id, ...body } = battlecard;
        return {
          url: `/battlecards/${id}.json`,
          method: 'PUT',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const {
          data: {
            cards,
            cardTitles,
            commentsCount,
            createdAt,
            id,
            layout,
            isDraft,
            profile,
            review,
            title,
            updatedAt,
            viewOrder,
          },
        } = await queryFulfilled;
        store.dispatch.battlecards.update({
          id,
          ...(cards && { cards }),
          ...(cardTitles && { cardTitles }),
          ...(typeof commentsCount === 'number' && { commentsCount }),
          ...(createdAt && { createdAt }),
          ...(typeof isDraft === 'boolean' && { isDraft }),
          ...(layout && { layout }),
          ...(review && { review }),
          ...(title && { title }),
          ...(updatedAt && { updatedAt }),
          ...(typeof viewOrder === 'string' && { viewOrder }),
          ...(profile.id && { profile: profile.id }),
        });
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createBattlecard: build.mutation<any, Partial<CreateBattlecardPayloadType>>(
      {
        query: (payload) => {
          const { profileId, ...body } = payload;

          return {
            url: `/profiles/${profileId}/battlecards.json`,
            method: 'POST',
            body,
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        transformResponse: (response: any) => {
          return response;
        },
      },
    ),
    deleteBattlecard: build.mutation<void, number>({
      query: (battlecardId) => ({
        url: `/battlecards/${battlecardId}.json`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      onQueryStarted: async (battlecardId, { queryFulfilled }) => {
        await queryFulfilled;
        store.dispatch.battlecards.delete(battlecardId);
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const enhancedApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Battlecards'],
});

export const {
  useUpdateBattlecardMutation,
  useCreateBattlecardMutation,
  useDeleteBattlecardMutation,
} = enhancedApi;
