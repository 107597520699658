import queryString from 'query-string';

import { api } from '../api';

import type {
  GetSuggestionsRequestQuery,
  GetSuggestionsResponse,
} from 'api/endpoints/suggestions';

export const { useGetSuggestionsQuery } = api.injectEndpoints({
  endpoints: (build) => ({
    getSuggestions: build.query<
      GetSuggestionsResponse,
      GetSuggestionsRequestQuery
    >({
      query: (params) => {
        return `suggest.json?${queryString.stringify(
          { ...params },
          {
            arrayFormat: 'bracket',
          },
        )}`;
      },
    }),
  }),
});
