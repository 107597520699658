import { Toolbar } from '@kluein/content-editor/toolbar';
import {
  Button,
  List,
  PhosphorIcon,
  type PhosphorIconComponent,
  type PhosphorIconElement,
  type ButtonProps,
} from '@kluein/klue-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { KlueEditor } from '@kluein/content-editor';

type CardEditorToolbarProps = {
  editor: KlueEditor;
  disabled?: boolean;
  cardId?: number;
  profileId?: number;
};

export type ToolbarOptionsMenu = {
  editor: KlueEditor;
  disabled?: boolean;
};

type IconButtonProps = Omit<ButtonProps, 'icon'> & {
  isActive?: boolean;
  isDisabled?: boolean;
  icon: PhosphorIconComponent | PhosphorIconElement;
};

const IconButton: React.FC<IconButtonProps> = ({
  isActive,
  isDisabled = false,
  icon,
  title,
  ...rest
}) => {
  const IconElement = React.isValidElement(icon)
    ? icon
    : React.createElement(icon as PhosphorIconComponent, {
        size: 20,
        weight: 'regular',
      });

  return (
    <Button
      pad="small"
      size="small"
      variant={isActive ? 'primary' : 'flat'}
      hoverIndicator
      disabled={isDisabled}
      icon={IconElement}
      title={title}
      data-active={isActive ? 'true' : 'false'}
      aria-pressed={isActive}
      {...rest}
    />
  );
};

export const HeadingMenuOptions: React.FC<ToolbarOptionsMenu> = ({
  editor,
  disabled,
}) => {
  const { t } = useTranslation(['Card']);

  const headingOptions = [
    { key: '1', label: 'Heading 1', icon: PhosphorIcon.TextHOne, level: 1 },
    { key: '2', label: 'Heading 2', icon: PhosphorIcon.TextHTwo, level: 2 },
    { key: '3', label: 'Heading 3', icon: PhosphorIcon.TextHThree, level: 3 },
    { key: '4', label: 'Paragraph', icon: PhosphorIcon.Paragraph, level: 0 },
  ];

  if (!editor) {
    return null;
  }

  const getActiveHeadingLevel = () => {
    for (let i = 1; i <= 3; i++) {
      if (editor.isActive('heading', { level: i })) {
        return i.toString();
      }
    }
    return editor.isActive('paragraph') ? '4' : '0';
  };

  return (
    <List
      a11yTitle={t('Card:editor.toolbar.headingMenu.title')}
      truncateLength={15}
      itemGap="xsmall"
      itemProps={{ size: 'small' }}
      selectedItem={getActiveHeadingLevel()}
      pad="small"
      data={headingOptions.map((option) => ({
        ...option,
        icon: <option.icon weight="regular" size={20} />,
        onClick: () => {
          if (option.level === 0) {
            editor.chain().focus().setParagraph().run();
          } else {
            // TODO: Fix missing types in the editor chain
            (editor.chain() as any)
              .focus()
              .toggleHeading({ level: option.level })
              .run();
          }
        },
        disabled:
          editor.isActive(
            option.level === 0 ? 'paragraph' : 'heading',
            option.level === 0 ? undefined : { level: option.level },
          ) || disabled,
      }))}
    />
  );
};

export const MoreMenuOptions: React.FC<ToolbarOptionsMenu> = ({
  editor,
  disabled,
}) => {
  const { t } = useTranslation(['Card']);

  if (!editor) {
    return null;
  }

  const getSelectedItem = () => {
    const activeItems = [
      { key: '1', condition: editor.isActive('image') },
      { key: '2', condition: editor.isActive('video') },
      { key: '3', condition: editor.isActive('embed') },
      { key: '4', condition: editor.isActive('horizontalRule') },
    ];

    const activeItem = activeItems.find((item) => item.condition);
    return activeItem ? activeItem.key : undefined;
  };

  return (
    <List
      a11yTitle={t('Card:editor.toolbar.moreMenu.title')}
      truncateLength={15}
      itemGap="xsmall"
      selectedItem={getSelectedItem()}
      itemProps={{
        size: 'small',
      }}
      pad={'small'}
      data={[
        {
          key: '1',
          label: 'Image',
          onClick: () => editor.chain().focus().addImageBlock().run(),
          disabled:
            !editor.isExtensionEnabled('image') ||
            !editor.can().chain().focus().addImageBlock().run(),
          icon: <PhosphorIcon.Image weight="regular" size={20} />,
        },
        {
          key: '2',
          label: 'Video',
          onClick: () => editor.chain().focus().setVideo({ src: '' }).run(),
          disabled: !editor.isExtensionEnabled('video'),
          icon: <PhosphorIcon.Video weight="regular" size={20} />,
        },
        {
          key: '3',
          label: 'Iframe',
          onClick: () => editor.chain().focus().insertEmbed().run(),
          disabled: !editor.isExtensionEnabled('embed'),
          icon: <PhosphorIcon.CodeBlock weight="regular" size={20} />,
          separator: true,
        },
        {
          key: '4',
          label: 'Horizontal Line',
          onClick: () => editor.chain().focus().setHorizontalRule().run(),
          disabled:
            !editor.isExtensionEnabled('horizontalRule') ||
            disabled ||
            !editor.can().setHorizontalRule(),
          icon: <PhosphorIcon.Minus weight="regular" size={20} />,
        },
      ]}
    />
  );
};

export const CardEditorToolbar: React.FC<CardEditorToolbarProps> = ({
  editor,
  disabled = false,
  cardId,
  profileId,
}) => {
  const { t } = useTranslation(['Card']);

  if (!editor) {
    return null;
  }

  return (
    <Toolbar
      // @TODO: Depends on Editor exported types
      editor={editor as any}
      config={[
        () => (
          <IconButton
            icon={<PhosphorIcon.TextH weight="regular" size={16} />}
            rightSlot={<PhosphorIcon.CaretDown weight="regular" size={12} />}
            onClick={() => {}}
            isDisabled={disabled}
            size="small"
            isActive={editor.isActive('heading')}
            dropProps={{
              pad: 'small',
            }}
            dropContent={
              <HeadingMenuOptions editor={editor} disabled={disabled} />
            }
            title={t('Card:editor.toolbar.headingMenu.title')}
          />
        ),
        ['bulletList', 'orderedList', 'blockquote', 'table'],
        () => (
          <IconButton
            icon={<PhosphorIcon.CaretDown weight="regular" size={12} />}
            onClick={() => {}}
            label={t('Card:editor.toolbar.moreMenu.title')}
            style={{ width: '64px' }}
            dropContent={
              <MoreMenuOptions editor={editor} disabled={disabled} />
            }
            dropProps={{
              pad: 'small',
            }}
            title={t('Card:editor.toolbar.moreMenu.title')}
          />
        ),
        () => (
          <IconButton
            pad="small"
            size="small"
            hoverIndicator
            icon={
              <PhosphorIcon.ChartBarHorizontal weight="regular" size={20} />
            }
            title={t('Card:editor.toolbar.dynamicBlockMenu.title')}
            onClick={() => {
              editor
                .chain()
                .focus()
                .insertDynamicBlock({
                  formulaKey: null,
                  formulaType: null,
                  profileId,
                  cardId,
                  companies: ['currentRival'],
                })
                .run();
            }}
          />
        ),
        ['bold', 'italic', 'underline', 'link'],
        'clearFormatting',
      ]}
    />
  );
};
