import { tokens, PhosphorIcon } from '@kluein/klue-ui';

import type {
  KlueCardCardVisibilityGroupType,
  KlueCardVisibilityGroupSettingsParams,
} from './KlueCardVisibilityGroups.types';

export const permissionIds = {
  curatorsOnly: 0,
  everyone: 1,
  curatorsAnd: 2,
};

export const VISIBILITY_GROUPS_COLORS = {
  curatorsOnly: {
    border: tokens.color['tertiary-2'].main,
    background: tokens.color['tertiary-2'].light,
    color: tokens.color['tertiary-2'].dark,
  },
  everyone: {
    border: tokens.color.tertiary.main,
    background: tokens.color.tertiary.light,
    color: tokens.color.tertiary.dark,
  },
  fullAccessUser: {
    border: tokens.color.secondary.main,
    background: tokens.color.secondary.light,
    color: tokens.color.primary.main,
  },
};

export const getVisibilityGroupColors = ({
  isDraft,
  allAccess,
  visibilityGroups,
}: KlueCardVisibilityGroupSettingsParams) => {
  if (isDraft) {
    return VISIBILITY_GROUPS_COLORS.curatorsOnly;
  }
  if (allAccess) {
    return VISIBILITY_GROUPS_COLORS.everyone;
  }
  return VISIBILITY_GROUPS_COLORS.fullAccessUser;
};

export const getVisibilityGroupUIElements = ({
  isDraft,
  allAccess,
  visibilityGroups,
  translate,
}: KlueCardVisibilityGroupSettingsParams) => {
  if (isDraft) {
    return {
      ...VISIBILITY_GROUPS_COLORS.curatorsOnly,
      text:
        translate?.('Common:visibilityTypeTitles.curatorsOnly') ||
        'Common:visibilityTypeTitles.curatorsOnly',
      icon: PhosphorIcon.EyeSlash,
      size: tokens.iconSize.medium,
    };
  }

  if (allAccess) {
    return {
      ...VISIBILITY_GROUPS_COLORS.everyone,
      text:
        translate?.('Common:visibilityTypeTitles.everyone') ||
        'Common:visibilityTypeTitles.everyone',
      icon: PhosphorIcon.Eye,
      size: tokens.iconSize.medium,
    };
  }

  const groupString = (visibilityGroups || []).reduce(
    (acc, { id, name }: KlueCardCardVisibilityGroupType, index: number) => {
      if (index === 0) {
        return name;
      }
      const count = visibilityGroups.length;
      // the last item to process
      if (index === count - 1) {
        if (count > 1) {
          return `${acc}, and ${name}`;
        }

        return name;
      }

      return `${acc}, ${name}`;
    },
    '',
  );

  return {
    ...VISIBILITY_GROUPS_COLORS.fullAccessUser,
    text:
      groupString ||
      translate?.('Common:visibilityTypeTitles.fullAccessUsers') ||
      'Common:visibilityTypeTitles.fullAccessUsers',
    icon: PhosphorIcon.UsersThree,
    size: tokens.iconSize.medium,
  };
};

export const getVisibleToValue = ({
  isDraft,
  allAccess,
  visibilityGroups,
}: KlueCardVisibilityGroupSettingsParams) => {
  if (isDraft) {
    return permissionIds.curatorsOnly;
  }
  if (allAccess) {
    return permissionIds.everyone;
  }
  if (visibilityGroups) {
    return permissionIds.curatorsAnd;
  }
  return permissionIds.curatorsOnly;
};
