import {
  Box,
  Tip,
  Button,
  TextInput,
  type DropProps,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { t } from 'i18next';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AnalyticsCategory } from 'lib/analytics';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { KlueCardTags } from '../../klueCard/partials';

import type { KlueCardTagDataType } from 'api/api.types';
import type { StateType as TagsStateType } from 'store/models/tags/tags.model';
import type { RootState } from 'store/store.types';

export type TagSuggestionType = string;

type SelectedSuggestionType = {
  suggestion: TagSuggestionType;
};

type CardEditorTagsProps = {
  tags?: Array<KlueCardTagDataType>;
  onAddTag: (tag: KlueCardTagDataType) => void;
  onRemoveTag: (tag: KlueCardTagDataType) => void;
};

const CardEditorTags = ({
  tags,
  onAddTag,
  onRemoveTag,
}: CardEditorTagsProps) => {
  const [showTagsInput, setShowTagsInput] = useState(false);
  const [searchTagInputValue, setSearchTagInputValue] = useState('');
  const [tagSuggestions, setTagSuggestions] = useState<Array<string>>([]);
  const textRef = useRef<HTMLInputElement>(null);
  const { byId } = useSelector<RootState, TagsStateType>((state) => state.tags);
  const [allTags, setAllTags] = useState<Array<KlueCardTagDataType>>([]);
  const [unusedTags, setUnusedTags] = useState<Array<KlueCardTagDataType>>([]);

  useEffect(() => {
    const allTagsArray = Array.from(byId.values());
    setAllTags(allTagsArray);

    const unusedTagsArray = allTagsArray.filter(
      (tag) => !tags?.find((t) => t.id === tag.id),
    );
    setUnusedTags(unusedTagsArray);

    const tagSuggestionsArray = unusedTagsArray.map((tag) => tag.name);
    setTagSuggestions(tagSuggestionsArray);

    if (showTagsInput && !unusedTagsArray.length) {
      setShowTagsInput(false);
    }
  }, [byId, tags, showTagsInput]);

  if (!tags) return null;

  const onTagInputChange = (event: any) => {
    const nextValue = event.target.value;

    setSearchTagInputValue(nextValue);

    if (!nextValue) return setTagSuggestions(unusedTags.map((tag) => tag.name));

    return setTagSuggestions(
      unusedTags
        .filter(({ id, name }) => {
          return name.toLowerCase().includes(nextValue.toLowerCase());
        })
        .map((tag) => tag.name),
    );
  };

  const handleClickAddTag = () => {
    setShowTagsInput(true);
    if (textRef.current) textRef.current.focus();
  };

  const keepDropDownListOpen = () => {
    textRef?.current?.blur?.();
    textRef?.current?.focus?.();
  };

  const handleOnSuggestionSelect = ({ suggestion }: SelectedSuggestionType) => {
    setSearchTagInputValue('');
    const lowerCaseValue = suggestion?.toLowerCase();

    if (!lowerCaseValue) return;

    const tag = allTags.find((t) => t.name.toLowerCase() === lowerCaseValue);

    if (!tag) return;

    onAddTag(tag);
    keepDropDownListOpen();
  };

  return (
    <Box direction="row">
      {showTagsInput ? (
        <Box>
          <TextInput
            width={{ min: 'medium' }}
            ref={textRef}
            value={searchTagInputValue}
            onChange={onTagInputChange}
            suggestions={tagSuggestions}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onClick={() => setShowTagsInput(false)}
            onSuggestionsOpen={() => setShowTagsInput(true)}
            onSuggestionSelect={handleOnSuggestionSelect}
            onBlur={() => {
              if (!tagSuggestions.length) setShowTagsInput(false);
            }}
            dropProps={
              {
                onEsc: () => setShowTagsInput(false),
                onClickOutside: () => setShowTagsInput(false),
                'data-test-id':
                  TEST_IDS.intelCard.footer.topicsDropdownContainer,
              } as DropProps & { 'data-test-id': string }
            }
            dropHeight="medium"
            variant="default"
            placeholder={t('Card:tags.search.placeholder')}
            style={{
              position: 'relative',
            }}
          />
        </Box>
      ) : (
        <Tip
          dropProps={{ align: { bottom: 'top' } }}
          content={t('Card:tags.addOrRemoveTags.add.label')}
        >
          <Box width={{ min: '26px' }}>
            <Button
              data-test-id={TEST_IDS.editor.tags.addTag}
              data-tracking-id={TRACKING_IDS.editor.tags.addTag}
              variant="primary"
              size="small"
              onClick={handleClickAddTag}
              icon={<PhosphorIcon.Plus weight="bold" />}
              disabled={!unusedTags.length}
            />
          </Box>
        </Tip>
      )}
      <KlueCardTags
        tags={tags || []}
        analytics={{ category: AnalyticsCategory.cardInteraction }}
        isCardEditorContent={true}
        onTagRemove={onRemoveTag}
      />
    </Box>
  );
};

export default CardEditorTags;
