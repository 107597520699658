/* eslint-disable max-lines */
import {
  type ListItemType,
  Dialog,
  DialogType,
  NotifierType,
  useNotifier,
  ProgressBarState,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useUpdateVisibilityGroups from 'components/common/klueCard/hooks/use-update-visibility-groups/useUpdateVisibilityGroups';
import { getVisibleToValue } from 'components/common/klueCard/partials/klue-card-visibility-groups/helpers';
import { useAuth } from 'contexts/auth';
import { useCardViewer } from 'hooks/use-card-viewer';
import { useRedirect } from 'hooks/use-redirect';
import useVisibilityGroups from 'hooks/use-visibility-groups/useVisibilityGroups';
import { AnalyticsAction, type AnalyticsActionType } from 'lib/analytics';
import { useDeleteCardMutation } from 'store/api/cards/cards.api';

import { useCardInteractionLog } from '../../hooks';
import { useKlueCardMenu } from '../../hooks/use-klue-card-menu';
import KlueCardAssignToBattlecardModal from '../klue-card-assign-to-battlecard-modal/KlueCardAssignToBattlcardModal';
import KlueCardMoveModal from '../klue-card-move-modal/KlueCardMoveModal';
import { KlueCardSourcesModal } from '../klue-card-sources-modal';
import KlueCardVisibilityGroupsModal from '../klue-card-visibility-groups/KlueCardVisibilityGroupsModal';

import type {
  KlueCardMenuItemProps,
  KlueCardMenuProps,
  KlueCardSnowPlowLabelType,
} from './KlueCardMenu.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

function KlueCardMenu(props: KlueCardMenuProps) {
  const {
    sourcesCount,
    cardId,
    profileId,
    children,
    trackingIds,
    isEditable = false,
    hideOpenCard = false,
    hideCardSources = false,
    rank,
  } = props;
  const { redirectToCardEditor } = useRedirect();
  const { t } = useTranslation();
  const { open } = useCardViewer();
  const {
    card,
    view,
    handleCopyEmbed,
    handleCopyLink,
    handleDuplicateCard,
    handleMoveCard,
  } = useKlueCardMenu({ cardId, profileId });
  const { isCurator, isNewCardEditorEnabled } = useAuth();
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const [isMovingCard, setIsMovingCard] = useState(false);
  const [isEditingSources, setIsEditingSources] = useState(false);
  const [isAssigningToBattlecards, setIsAssigningToBattlecards] =
    useState(false);
  const [isAssigningCardPermissions, setIsAssigningCardPermissions] =
    useState(false);
  const {
    visibleTo,
    setVisibleTo,
    setSelectGroups,
    selectedGroups,
    visibilityGroups: allVisibilityGroups,
    getCardPermissionCardsData,
  } = useVisibilityGroups();
  const { onVisibilityGroupSelected } = useUpdateVisibilityGroups();

  const handleDoneVisibilityGroupSelected = () => {
    setIsAssigningCardPermissions(false);
    if (onVisibilityGroupSelected) {
      const cardPermissionCardsData: GetCardPermissionCardsDataResult =
        getCardPermissionCardsData({
          visibleTo,
          selectedGroups,
          selectedCards: new Set([cardId ?? 0]),
          visibilityGroups: allVisibilityGroups,
        });

      onVisibilityGroupSelected(cardId, cardPermissionCardsData);
    }
  };

  const hasCardSources = !!sourcesCount;
  const menuItemsObj: {
    duplicateCard: KlueCardMenuItemProps;
    moveCard: KlueCardMenuItemProps;
    openCard: KlueCardMenuItemProps;
    sources: KlueCardMenuItemProps;
    edit: KlueCardMenuItemProps;
    assignToBattlecards: KlueCardMenuItemProps;
    copyLink: KlueCardMenuItemProps;
    copyEmbed: KlueCardMenuItemProps;
    delete: KlueCardMenuItemProps;
    cardPermissions: KlueCardMenuItemProps;
  } = {
    openCard: {
      key: 'open-card',
      label: t('Card:header.menu.openCard'),
      icon: <PhosphorIcon.ArrowSquareIn />,
    },
    sources: {
      key: 'sources',
      label: `${t('Card:header.menu.sources')}${
        sourcesCount ? ` (${sourcesCount})` : ''
      }`,
      icon: <PhosphorIcon.Info />,
    },
    edit: {
      key: 'edit',
      label: t('Common:actions.edit'),
      icon: <PhosphorIcon.PencilSimple />,
      separator: true,
    },
    assignToBattlecards: {
      key: 'assign-to-battlecards',
      label: t('Card:header.menu.assignToBattlecards'),
      icon: <PhosphorIcon.Star />,
    },
    cardPermissions: {
      key: 'card-permissions',
      label: t('Card:header.menu.cardPermissions'),
      icon: <PhosphorIcon.Eye />,
      separator: true,
    },
    duplicateCard: {
      key: 'duplicate-card',
      label: t('Card:header.menu.duplicateCard'),
      icon: <PhosphorIcon.Copy />,
      separator: true,
    },
    moveCard: {
      key: 'move-card',
      label: t('Card:header.menu.moveCard'),
      icon: <PhosphorIcon.ArrowsLeftRight />,
      separator: false,
    },
    copyLink: {
      key: 'copy-link',
      label: t('Card:header.menu.copyLink'),
      icon: <PhosphorIcon.Link />,
    },
    copyEmbed: {
      key: 'copy-embed',
      label: t('Card:header.menu.copyEmbed'),
      icon: <PhosphorIcon.Code />,
      separator: view === 'board',
    },
    delete: {
      key: 'delete',
      label: t('Common:actions.delete'),
      icon: <PhosphorIcon.Trash />,
    },
  };
  if (trackingIds) {
    menuItemsObj.copyEmbed.trackingId = trackingIds.copyEmbed;
    menuItemsObj.copyLink.trackingId = trackingIds.copyLink;
    menuItemsObj.duplicateCard.trackingId = trackingIds.duplicateCard;
    menuItemsObj.moveCard.trackingId = trackingIds.moveCard;
    menuItemsObj.openCard.trackingId = trackingIds.openCard;
    menuItemsObj.sources.trackingId = trackingIds.sources;
    menuItemsObj.edit.trackingId = trackingIds.edit;
    menuItemsObj.assignToBattlecards.trackingId =
      trackingIds.assignToBattlecards;
    menuItemsObj.cardPermissions.trackingId = trackingIds.cardPermissions;
  }
  // TODO: Refactor to use the order that keys are defined in the menuItemsObj above
  const menuItems = [];
  if (!hideOpenCard && !isCurator) {
    menuItems.push(menuItemsObj.openCard);
  }
  if (isEditable && isCurator) {
    menuItems.push(menuItemsObj.edit);
  }
  if (!hideCardSources) {
    if (isCurator || (!isCurator && hasCardSources)) {
      menuItems.push(menuItemsObj.sources);
    }
  }
  if (isEditable && isCurator && view === 'board') {
    menuItems.push(menuItemsObj.assignToBattlecards);
  }
  if (
    isEditable &&
    isCurator &&
    isNewCardEditorEnabled &&
    (view === 'board' || view === 'battlecard')
  ) {
    menuItems.push(menuItemsObj.cardPermissions);
  }
  if (isEditable) {
    menuItems.push(menuItemsObj.copyLink);
  }
  if (isEditable && isCurator) {
    menuItems.push(menuItemsObj.copyEmbed);
  }
  if (isEditable && isCurator && view === 'board') {
    menuItems.push(menuItemsObj.moveCard);
    menuItems.push(menuItemsObj.duplicateCard);
  }
  if (isEditable && isCurator && isNewCardEditorEnabled && view === 'board') {
    menuItems.push(menuItemsObj.delete);
  }

  const logCardInteraction = useCardInteractionLog();
  const handleClickMenuItem = (
    item: ListItemType,
    snowplowLabel: KlueCardSnowPlowLabelType,
  ) => {
    if (cardId) {
      const options: { scrollToSources?: boolean; rank?: number | null } = {
        rank,
      };
      let action: AnalyticsActionType = AnalyticsAction.expand;
      if (item.key === 'sources' && isCurator) {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.viewSources,
          label: snowplowLabel,
          rank,
        });
        return setIsEditingSources(true);
      }
      if (item.key === 'sources' && !isCurator) {
        options.scrollToSources = true;
        action = AnalyticsAction.viewSources;
      }
      if (item.key === 'edit') {
        action = AnalyticsAction.edit;
        logCardInteraction({
          cardId,
          action,
          label: snowplowLabel,
          rank,
        });
        return profileId && redirectToCardEditor({ profileId, cardId });
      }

      if (item.key === 'copy-embed') {
        action = AnalyticsAction.copyEmbed;
        logCardInteraction({
          cardId,
          action,
          label: snowplowLabel,
          rank,
        });
        return handleCopyEmbed();
      }

      if (item.key === 'copy-link') {
        action = AnalyticsAction.copyLink;
        logCardInteraction({
          cardId,
          action,
          label: snowplowLabel,
          rank,
        });
        return handleCopyLink();
      }

      if (item.key === 'duplicate-card') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.duplicateCard,
          label: snowplowLabel,
          rank,
        });
        return handleDuplicateCard();
      }

      if (item.key === 'move-card') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.moveCard,
          label: snowplowLabel,
          rank,
        });
        return setIsMovingCard(true);
      }

      if (item.key === 'delete') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.removeCard,
          label: snowplowLabel,
          rank,
        });

        setIsDeletingCard(true);

        return;
      }

      if (item.key === 'assign-to-battlecards') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.assignToBattlecards,
          label: snowplowLabel,
          rank,
        });

        setIsAssigningToBattlecards(true);

        return;
      }

      if (item.key === 'card-permissions') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.cardPermissions,
          label: snowplowLabel,
          rank,
        });

        const {
          isDraft = true,
          allAccess = false,
          visibilityGroups = [],
        } = card || {};
        const visibleTo = getVisibleToValue({
          isDraft,
          allAccess,
          visibilityGroups,
        });
        setVisibleTo(visibleTo);
        setSelectGroups(visibilityGroups.map(({ id }) => id));
        setIsAssigningCardPermissions(true);

        return;
      }

      open(cardId, options);
      logCardInteraction({
        cardId,
        action,
        label: snowplowLabel,
        rank,
      });
    }
  };

  return (
    <>
      {isDeletingCard && cardId && (
        <CardDeleteConfirmation
          cardId={cardId}
          onClose={() => setIsDeletingCard(false)}
        />
      )}
      {isMovingCard && cardId && (
        <KlueCardMoveModal
          currentBoardId={card?.board?.id}
          handleClose={() => setIsMovingCard(false)}
          handleSave={handleMoveCard}
        />
      )}
      {isEditingSources && cardId && (
        <KlueCardSourcesModal
          cardId={cardId}
          handleClose={() => setIsEditingSources(false)}
        />
      )}
      {isAssigningToBattlecards && cardId && (
        <KlueCardAssignToBattlecardModal
          id={cardId}
          profileId={profileId}
          onClose={() => setIsAssigningToBattlecards(false)}
        />
      )}
      {isAssigningCardPermissions && cardId && (
        <KlueCardVisibilityGroupsModal
          visibleTo={visibleTo}
          onClose={() => setIsAssigningCardPermissions(false)}
          onDone={handleDoneVisibilityGroupSelected}
        />
      )}
      {children({ menuItems, handleClickMenuItem })}
    </>
  );
}

type CardDeleteConfirmationProps = {
  cardId: number;
  onClose: () => void;
};

const CardDeleteConfirmation = ({
  cardId,
  onClose,
}: CardDeleteConfirmationProps) => {
  const { t } = useTranslation(['Card']);
  const [deleteCard, deleteState] = useDeleteCardMutation();
  const isDeleting = deleteState.isLoading;
  const { notify } = useNotifier();
  const dispatch = useDispatch();
  function handleSubmit() {
    deleteCard(cardId)
      .unwrap()
      .then(() => {
        dispatch.boards.deleteCards({ cardIds: [cardId] });
        dispatch.cards.delete({ cardIds: [cardId] });
        return notify({
          type: NotifierType.SUCCESS,
          message: t('Card:deleteCard.success'),
          icon: <PhosphorIcon.WarningCircle />,
        });
      })
      .catch((error) => {
        return notify({
          type: NotifierType.ERROR,
          message: t('Card:deleteCard.failure'),
          icon: <PhosphorIcon.WarningCircle />,
        });
      })
      .finally(() => {
        onClose();
      });
  }
  return (
    <Dialog
      title={t('Card:deleteCard.confirmation.heading')}
      description={t(
        `Card:deleteCard.confirmation.${isDeleting ? 'deleting' : 'body'}`,
      )}
      showCancelButton={!isDeleting}
      show
      progressBarProps={
        isDeleting
          ? {
              hideLabel: false,
              min: 0,
              max: 100,
              value: 50,
              state: ProgressBarState.ACTIVE,
              a11yTitle: '',
            }
          : undefined
      }
      showActionButton={!isDeleting}
      action={handleSubmit}
      actionLabel={t('Card:deleteCard.confirmation.confirmButton')}
      position="center"
      onClose={onClose}
      type={DialogType.WARNING}
    />
  );
};

export default KlueCardMenu;
