/* eslint-disable max-lines */
// TODO: [Tech-Debt] Refactor api.types to separate modules
import type { JSONContent } from '@kluein/content-editor';
import type {
  Format,
  Length,
} from 'components/common/summary-settings-button/utils';
import type { TextJson } from 'klue-html/formulas/formulas.types';
import type { REPORT_KEYS } from 'pages/reports/utils';

export type KlueCardTagDataType = {
  id: number;
  name: string;
  highlight?: boolean;
};

export type KlueCardTagsDataType = KlueCardTagDataType[];

export type UserType = {
  apiKeys: {
    intercomUserHash: string;
    googleMaps: string;
  };
  companyName: string;
  createdAt: string;
  currentSignInAt: string;
  email: string;
  emailDigest: boolean;
  firstName: string;
  id: number;
  image: string;
  isImpersonating: boolean;
  isInternal: boolean;
  isStaff: boolean;
  canImpersonate: boolean;
  lastName: string;
  lastSeenAt: string;
  name: string;
  roles: Array<string>;
  curatingRivals?: Array<number>;
  userData: UserDataType;
  bio: string;
  title: string;
  username: string;
  visibilityGroupId: number;
  employeeId: string;
  prettyName: string;
  csrfToken: string;
  commentsCount: number;
  likesCount: number;
  postsCount: number;
  curatingProfilesCount: number;
  inMails: Array<string>;
  emailAliases: Array<unknown>;
  curatingProfiles: Array<number>;
  curatingAllProfiles: Array<number>;
  curatingAllRivals: Array<number>;
  emailNotify: boolean;
  passwordAuthEnabled: boolean;
  pendingInvitation: boolean;
  deletedAt?: string;
  confirmedAt: string;
};

export enum KlueCardSentimentEnum {
  POSITIVE = 1,
  NEGATIVE,
}

export type UserDataType = {
  battlecardLayouts?: { [key: string]: BattlecardLayout };
  boardNewsVisits?: { [boardId: number]: string };
  collapsedProfileLanes?: { [key: string]: Array<string | number> };
  defaults?: {
    boards?: { tocLanesOpen: boolean; tocBattlecardsOpen: boolean };
    dashboard?: {
      sortBy?: string;
      activeGroupId?: number;
      collapsedGroups?: number[];
    };
  };
  digest?: { introViewed: boolean };
  dismissedV2NotificationAt?: number;
  emailDigestForceSentAt?: string;
  emailDigestSentAt?: string;
  favoritedCompanies?: { id: number; name: string; domain: string }[];
  alertsPastFirstLoad?: boolean;
  mobile?: { ios: boolean; android: boolean };
  recentlyViewed?: RecentlyViewedType;
  recentlyViewedProfiles?: number[];
  weeklyReviewData?: { filteredByRivalGroups: number[] };
  cardSentiment?: { [cardId: number]: KlueCardSentimentEnum };
  defaultAlertsSummarySettings: { length: Length; form: Format };
  defaultReviewInsightsSummarySettings: { format: Format };
  dashboardNewsToggle?: boolean;
  isWinLossEnabled?: boolean;
  isWinLossConsumerAccessEnabled?: boolean;
  isSeller?: boolean;
  isCompetitiveDealWidgetVisible?: boolean;
};

export type RecentlyViewedType = {
  profile: Array<{ id: number; name: string; logo: string }>;
};

export type UsageReportsType = {
  [key in REPORT_KEYS]: string | { [key in REPORT_KEYS]: string };
};

export type CompanyFeatureFlagType =
  | 'isSomeFeatureEnabled'
  | 'isSomeOtherFeatureEnabled'
  | 'isCompanyProfileEnabled'
  | 'isComposerInsightsSelectionEnabled'
  | 'isV2CuratorDashboardDisabled'
  | 'isKnowledgeHubEnabled'
  | 'isKnowledgeHubVariablesEnabled'
  | 'isKnowledgeHubFilesEnabled'
  | 'isAskKlueFeedbackEnabled';

export type CompanyDataType = {
  cardTagIsConsumerVisible?: boolean;
  analytics?: {
    ga?: {
      debug?: boolean;
      trackingId: string;
    };
    sp?: {
      debug?: boolean;
      appId: string;
      collectorUrl: string;
    };
  };
  companySupportedRivalId?: number;
  defaults?: {
    dashboard?: {
      sortBy?: string;
      activeGroupId?: number;
    };
    battlecards?: {
      defaultLayout?: string;
    };
  };
  embedHtmlDynamicCardsEnabled?: boolean;
  enableSlackApp?: boolean;
  enableTeamsApp?: boolean;
  forceHome?: string;
  forceProfileLinksOnDashboard?: boolean;
  isExpandCardsDisabled?: boolean;
  isDashboardNewsDisabled?: boolean;
  isCardFeedbackDisabled?: boolean;
  isCardSentimentDisabled?: boolean;
  isCardSentimentAndFeedbackOnHoverDisabled?: boolean;
  isComposerEnabled?: boolean;
  isComposerEnabledForStaff?: boolean;
  isAlertsRecommendedTagEnabled?: boolean;
  queryBuilderEnabled?: boolean;
  reviewDataEnabled?: boolean;
  sfdc?: Record<any, any>;
  showCardInfo?: boolean;
  wizardEnabled?: boolean;
  isWinLossEnabled?: boolean;
  isWinLossConsumerAccessEnabled?: boolean;
  isWinLossExclusive?: boolean;
  isBattlecardLayoutLabelDisabled?: boolean;
  // Sub-toggles for individual Competitive Analytics reports, enabled with the approval workflow
  isCompetitiveAnalyticsOverviewReportEnabled?: boolean;
  isCompetitiveAnalyticsThreatAnalysisReportEnabled?: boolean;
  isCompetitiveAnalyticsRepPerformanceReportEnabled?: boolean;
  isCompetitiveAnalyticsDataHygieneReportEnabled?: boolean;
  isCompetitiveAnalyticsImpactReportEnabled?: boolean;
  isCompetitiveAnalyticsIndividualUserReportEnabled?: boolean;
  isCompetitiveAnalyticsProgramImpactReportEnabled?: boolean;
  // end of sub-toggles for Competitive Analytics reports
  // Sub-toggles for curator access to each report section
  isConsumerReportEnabledForCurators?: boolean;
  isCuratorReportEnabledForCurators?: boolean;
  isCRAReportEnabledForCurators?: boolean;
  // end of sub-toggles for curator access to each report section
  isCompetitiveDashboardEnabledForConsumers?: boolean;
  isAlertsNotificationEnabledForCurators?: boolean;
  isRivalLinkHiddenForConsumers?: boolean;
  isUnreadStateEnabledForConsumers?: boolean;
  isAlertsAutoSummarizeOnTriageEnabled?: boolean;
  isNewReportsEnabled?: boolean;
  isDashboardAskKlueEnabled?: boolean;
  isBattlecardAskKlueEnabled?: boolean;
  isDashboardAskKluePreviewForCuratorsEnabled?: boolean;
  isCompanyLandingPageEnabled?: boolean;
  isSupportedRivalSearchResultsEnabled?: boolean;
  isCompanyFavoritesEnabled?: boolean;
  isCompanyLinkDefaultBattlecard?: boolean;
  isCompanyLinkDefaultLandingPage?: boolean;
  isUpdateSummaryEnabled?: boolean;
  isDashboardNewsToggleDefaultOff?: boolean;
  isGAEnabled?: boolean;
  isReviewInsightsCreateCardEnabled?: boolean;
  researchDevEnabled?: boolean;
  researchChatEnabled?: boolean;
  researchEnabled?: boolean;
  researchEnabledForStaff?: boolean;
  isProfileSideNavNewsDisabled?: boolean;
  cardFreshnessDisabled?: boolean;
  alertTitleEditableDisabled?: boolean;
  isNewCuratorNavEnabled?: boolean;
  isFeedHiddenInConsumerNavEnabled?: boolean;
  isInsightsWhyWeWinLoseEnabled?: boolean;
  isCallInsightsDemoReplacementEnabled?: boolean;
  isSFDCDataSyncUserRoleEnabled?: boolean;
  isSFDSOnTemporalEnabled?: boolean;
  enableNewCardEditor?: boolean;
  enableNewCardRenderer?: boolean;
  isSearchSuggestionBattlecardsEnabled?: boolean;
  isGenAISearchResultsEnabled?: boolean;
  isSFDSCustomLookupObjectEnabled?: boolean;
  isHSDSEnabled?: boolean;
  isAskKlueWizardEnabled?: boolean;
  isAskKlueSingleClickPromptEnabled?: boolean;
  isConsumerAskKlueToggleHidden?: boolean;
  enableV2BattlecardEdit: boolean;
  stackerIsDefaultBattlecardLayoutDisabled?: boolean;
} & Partial<{ [key in CompanyFeatureFlagType]: boolean }>;

export type CompanyType = {
  companyData: CompanyDataType;
  createdAt: string;
  defaultCardVisibilityGroupIds: any[];
  defaultUserVisibilityGroupId: number;
  digestRecipientsCount: number;
  emailDomains: string[];
  iconUrl: string;
  id: number;
  kluebotId: number;
  name: string;
  passwordAuthEnabled: boolean;
  reviewFrequency: string;
  reviewItemsPerRival: number;
  reviewRole: string;
  slug: string;
  ssoAuthEnabled: boolean;
  updatedAt: string;
  url: string;
  usageReports?: UsageReportsType;
  ownOrganizationId: number;
};

export type UpdateBattlecardsType = {
  id: number;
  title?: string;
  cardData?: Record<'desktop', Array<number>>;
  isDraft?: boolean;
  viewOrder?: number;
  layout?: BattlecardLayout;
  markAsReviewed?: boolean;
  reviewedAt?: string;
  comments?: Record<'add' | 'remove', Array<number>>;
};

export type CreateBattlecardPayloadType = {
  profileId: number;
  title?: string;
  cardData?: Record<'desktop', Array<number>>;
  layout?: BattlecardLayout;
  isDraft?: boolean;
  viewOrder?: number;
  comments?: Array<number>;
};

export type BattlecardsType = {
  cards: {
    desktop: Array<number>;
    tablet: Array<number>;
    mobile: Array<number>;
  };
  cardTitles: Array<number>;
  commentsCount: number;
  createdAt: string;
  id: number;
  layout: BattlecardLayout;
  isDraft: boolean;
  profile: number;
  review: {
    reviewedAt: Date | null;
    byUserId: number | null;
  };
  title: string;
  updatedAt: string;
  viewOrder: string;
};

export type BattlecardLayout = 'stacker' | 'single' | 'masonry' | 'hero';

export type CardTitlesType = {
  id: number;
  name: string;
};

export type ProfileBoardCardsType = { id: number; height: string };

export type BoardsType = {
  cards?: Array<ProfileBoardCardsType>;
  cardsCount: number;
  createdAt: string;
  id: number;
  name: string;
  profile: number;
  shouldUpdate?: boolean;
  updatedAt: string;
  viewOrder: string;
};

export type ProfileType = {
  battlecards: Array<number>;
  battlecardsCount: number;
  boards: Array<number>;
  cardsCount: number;
  commentsCount: number;
  createdAt: Date;
  curators: Array<number>;
  curatorsCount: number;
  id: number;
  isDraft: boolean;
  isTemplate: boolean;
  name: string;
  scratchpadBoardId: number;
  updatedAt: Date;
  visibleBattlecardsCount: number;
  wideMode: boolean;
  rivalId: number;
};

export type CardSource = {
  createdAt: Date;
  highlightId: number;
  id: number;
  pageTitle: string;
  prettyName: string;
  targetUrl: string;
  url: string;
  userImage: string;
  userName: string;
  userUrl: string;
};

export type CardVisibilityGroupsType = Array<{ id: number; name: string }>;

export type CardDataType = {
  name: string | null;
  nameWithHighlight?: string;
  sources?: CardSource[] | null /** @TODO THIS SHOULD BE REMOVED SOON */;
  textHtml?: string;
  textHtmlWithHighlight?: string;
  textJson?: Array<TextJson>;
  jsonData?: JSONContent;
  hasDynamicBlocks?: boolean;
  useNewEditor?: boolean;
};

export type CardType = {
  allAccess: boolean;
  author: { id: number; name: string } | null;
  reviewer?: { id: number; reviewedAt: string } | null;
  board: { id: number; name: string; profileId: number };
  createdAt: string;
  data: CardDataType;
  displayName: string | null;
  id: number;
  isDraft: boolean;
  sourcesCount: number;
  tags: KlueCardTagsDataType;
  templateName: string;
  updatedAt: string;
  viewOrder: string;
  visibilityGroups: CardVisibilityGroupsType;
};

export type UpdateCardsType = {
  allAccess?: boolean;
  author?: number;
  boardId?: number;
  cardIds?: Array<number>;
  comments?: {
    remove?: Array<number>;
    add?: Array<number>;
  };
  data?: any;
  isDraft?: boolean;
  keepUpdatedAt?: boolean;
  templateName?: string;
  viewOrder?: number;
  visibilityGroups?: Array<number>;
  tags?: Array<number>;
};

export type DeleteCardsType = {
  cardIds: Array<number>;
};

export type DeleteBoardsType = {
  boardIds: Array<number>;
};

export type CardSourceType = {
  createdAt: string;
  description: string | null;
  id: number;
  metadata: {
    container?:
      | {
          profileId?: number;
          profileName?: string;
          battlecardId?: number;
          battlecardTitle?: string;
          postId?: number;
          postTitle?: string;
        }
      | Record<string, never>;
    migration?:
      | {
          highlightId?: number;
        }
      | Record<string, never>;
    referableMetadata?:
      | {
          title?: string;
          name?: string;
        }
      | Record<string, never>;
  };
  originalAuthor: string;
  permalink: string;
  prettyName: string;
  quote: string | null;
  referableId: number;
  referableType: 'Link' | 'Comment' | string;
  userAtName: string;
  userId: number;
  userImage: string;
};

export type AddCardSourceType = {
  url: string;
};

export type DeleteCardSourceType = {
  id: number;
};

export type ClearbitStatType = {
  Company: {
    name: string;
    legalName: string;
    description?: string;
    domain: string;
    linkedin?: {
      handle: string;
    };
    twitter?: {
      handle: string;
    };
    facebook?: {
      handle: string;
    };
  };
};

export type SpiderStatusType = {
  spiderId?: number;
  state?: 'enabled' | 'disabled' | 'undefined';
};

export type RivalType = {
  alertsActive: boolean;
  category: string;
  iconUrl: string;
  id: number;
  name: string;
  profile: number;
  searchableNamesRegex: string;
  slug: string;
  supportedRival: any;
  url: string;
  clearbitStat?: ClearbitStatType;
  // The rest are only available in minimal response
  battlecardsCount?: number;
  cardsCount?: number;
  createdAt: string;
  isDraft: boolean;
  isTemplate: boolean;
  profileId: number;
  supportedRivalId?: number;
  supportedRivalName?: string;
  spiderStatus?: SpiderStatusType;
};

export type RivalGroupRivalItemType = {
  iconUrl: string;
  id: number;
  name: string;
  profileId: number;
};

export type RivalGroupType = {
  createdAt: string;
  id: number;
  name: string;
  rivals: Array<number>;
  updatedAt: string;
  userId: number;
  viewOrder: string;
};

export type SellerRival = {
  openDealsCards: RivalDeal[];
  openDealCount: number;
  updatedAt: Date;
};

export type RivalDeal = {
  name: string;
  competitor: string;
  iconUrl: string;
  ctaLink?: string;
  boardId: number;
  battlecardId: number;
  openRevenue: number;
  openDeals: number;
  currency: string;
  snapshotDate: Date;
  rank: number;
};

export type VisibilityGroupType = {
  cardsCount: number;
  createdAt: string;
  id: number;
  isCardDefault: boolean;
  isUserDefault: boolean;
  name: string;
  updatedAt: string;
  usersCount: number;
  viewOrder: number;
};

export type TagType = {
  id: number;
  name: string;
  synonyms: string[];
};

export type SearchResultBoardType = {
  id: number;
  name: string;
  rival_id: number;
  rival_domain: string;
  rival_name: string;
};

export type SearchResultProfileType = {
  id: number;
  name: string;
};

export type SearchResultCardType = {
  id: number;
  created_at: Date;
  updated_at: Date;
  is_draft: boolean;
  in_battlecard: boolean;
  profile_draft: boolean;
  all_access: boolean;
  sources_count: number;
  text: string;
  company: {
    id: number;
  };
  visibility_groups: any[];
  tags: TagType[];
  data: CardDataType;
  card_template: {
    component_name: string;
  };
  board: SearchResultBoardType;
  profile: {
    id: number;
    name: string;
  };
  rank?: number;
};
