import { Box, Button, tokens, PhosphorIcon, Tip } from '@kluein/klue-ui';
import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useOnOutsideClick from 'hooks/use-on-outside-click/useOnOutsideClick';
import useVisibilityGroups from 'hooks/use-visibility-groups/useVisibilityGroups';
import { CardPermissions } from 'pages/profile/partials/sideAction/bulkEdit';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { getVisibilityGroupUIElements, getVisibleToValue } from './helpers';
import KlueCardVisibilityGroupsModal from './KlueCardVisibilityGroupsModal';

import type { KlueCardVisibilityGroupsProps } from './KlueCardVisibilityGroups.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';
const KlueCardVisibilityGroups = ({
  cardId,
  flex = 'shrink',
  isDraft = false,
  allAccess = false,
  visibilityGroups = [],
  children,
  zIndex = 0,
  minified = false,
  displayInModal = false,
  onVisibilityGroupSelected,
}: KlueCardVisibilityGroupsProps) => {
  const {
    selectedGroups,
    visibleTo,
    visibilityGroups: allVisibilityGroups,
    setSelectGroups,
    setVisibleTo,
    getCardPermissionCardsData,
  } = useVisibilityGroups();
  const [showVisibilityGroups, setShowVisibilityGroups] = useState(false);
  const { t } = useTranslation(['Card']);
  const ref = useRef<HTMLDivElement>(null);
  useOnOutsideClick({
    ref,
    onOutsideClick: () => {
      if (displayInModal) {
        return;
      }
      setShowVisibilityGroups(false);
    },
  });

  const visibilityGroupsSorted = (visibilityGroups || []).sort(
    ({ name: nameA }: { name: string }, { name: nameB }: { name: string }) => {
      if (nameA === nameB) {
        return 0;
      }
      return nameA < nameB ? -1 : 1;
    },
  );
  const {
    text,
    background,
    border,
    icon: IconComp,
  } = useMemo(
    () =>
      getVisibilityGroupUIElements({
        isDraft,
        allAccess,
        visibilityGroups: visibilityGroupsSorted,
        translate: t,
      }),
    [isDraft, allAccess, visibilityGroupsSorted, t],
  );

  const updateVisibleTo = useCallback(() => {
    const visibleTo = getVisibleToValue({
      isDraft,
      allAccess,
      visibilityGroups,
    });
    setVisibleTo(visibleTo);
  }, [isDraft, allAccess, visibilityGroups, setVisibleTo]);

  const handleVisibilityGroupSelected = () => {
    if (onVisibilityGroupSelected) {
      if (!showVisibilityGroups) {
        updateVisibleTo();
        setSelectGroups(visibilityGroups.map(({ id }) => id));
      }
      setShowVisibilityGroups(!showVisibilityGroups);
    }
  };

  const handleDoneVisibilityGroupSelected = () => {
    setShowVisibilityGroups(false);
    if (onVisibilityGroupSelected) {
      const cardPermissionCardsData: GetCardPermissionCardsDataResult =
        getCardPermissionCardsData({
          visibleTo,
          selectedGroups,
          selectedCards: new Set([cardId ?? 0]),
          visibilityGroups: allVisibilityGroups,
        });

      onVisibilityGroupSelected(cardPermissionCardsData);
    }
  };

  useEffect(() => {
    updateVisibleTo();
  }, [updateVisibleTo]);

  if (!text.length) {
    return null;
  }

  return (
    <Box
      direction="row"
      align="center"
      flex={flex}
      data-test-id={TEST_IDS.klueCard.visibilityGroups.container}
      style={{ position: 'relative', zIndex }}
      ref={ref}
      fill={!minified ? 'horizontal' : false}
    >
      <Tip
        content={text}
        dropProps={{ align: { bottom: 'top' } }}
        variant="small"
        color="dark"
      >
        <Box
          justify="center"
          align="center"
          width="2em"
          height="2em"
          flex={{ shrink: 0 }}
          background={background}
          round="xxsmall"
          border={{ side: 'all', color: border }}
          onClick={
            onVisibilityGroupSelected
              ? handleVisibilityGroupSelected
              : undefined
          }
        >
          <IconComp
            color={border}
            size={tokens.iconSize.small}
            weight="regular"
          />
        </Box>
      </Tip>
      {showVisibilityGroups && !displayInModal && (
        <Box
          background={tokens.color.neutral.white.main}
          // setting maxWidth to 300px here since setting it in Box
          // seems to not work with absolute positioning (in some cases - e.g. disabled checkboxes)
          style={{
            position: 'absolute',
            bottom: 0,
            maxWidth: '300px',
            zIndex: 1,
          }}
          elevation="xlarge"
          height={{ max: '500px' }}
          round="xxsmall"
        >
          <Box pad="small">
            <CardPermissions visibleToDefault={visibleTo} showTitle={false} />
          </Box>
          <Box
            pad="small"
            justify="end"
            align="end"
            focusIndicator={false}
            flex={{ shrink: 0 }}
          >
            <Button
              variant="flat-outlined-form"
              label={t('Card:editor.visibilityGroups.done')}
              onClick={handleDoneVisibilityGroupSelected}
              data-test-id={TEST_IDS.klueCard.visibilityGroups.done}
              data-tracking-id={TRACKING_IDS.editor.visibilityGroups.done}
            />
          </Box>
        </Box>
      )}
      {!minified && (
        <Box flex={{ shrink: 1 }} margin={{ horizontal: 'small' }}>
          <Button
            data-test-id={TEST_IDS.klueCard.visibilityGroups.text}
            variant="flat-outlined-form"
            leftSlot={<IconComp size={tokens.iconSize.small} />}
            label={text}
            onClick={
              onVisibilityGroupSelected
                ? handleVisibilityGroupSelected
                : undefined
            }
            rightSlot={
              onVisibilityGroupSelected && (
                <PhosphorIcon.CaretDown size={tokens.iconSize.small} />
              )
            }
            style={{ width: 'fit-content' }}
          />
        </Box>
      )}
      {children ? (
        <Box
          flex={{ grow: 1 }}
          direction="row"
          pad={{ vertical: 'small' }}
          justify="end"
        >
          {children}
        </Box>
      ) : null}
      {showVisibilityGroups && displayInModal && (
        <KlueCardVisibilityGroupsModal
          visibleTo={visibleTo}
          onClose={() => setShowVisibilityGroups(false)}
          onDone={handleDoneVisibilityGroupSelected}
        />
      )}
    </Box>
  );
};

export default KlueCardVisibilityGroups;
