import useAuth from 'contexts/auth/useAuth';

import CardEditor from './CardEditor';
import useCardEditorModalContainer from './hooks/useCardEditorModalContainer';

const CardEditorModalContainer = () => {
  const { isCurator, isNewCardEditorEnabled } = useAuth();
  const { targetCard, close, profile, isLoading, failed, isNewCard } =
    useCardEditorModalContainer();

  if ((!targetCard && !isNewCard) || !isCurator || !isNewCardEditorEnabled) {
    return null;
  }

  return (
    <CardEditor
      onClose={close}
      card={targetCard}
      profileId={profile?.id}
      isLoading={isLoading}
      failedLoading={failed}
    />
  );
};

export default CardEditorModalContainer;
