import { Anchor, Box, Card, tokens } from '@kluein/klue-ui';
import { useCallback, useMemo } from 'react';

import { useAuth } from 'contexts/auth';
import { useCardViewer } from 'hooks/use-card-viewer';
import { useRedirect } from 'hooks/use-redirect';
import { CARD_HEADER_ALLOWED_TAGS } from 'klue-html/constants';
import sanitize from 'klue-html/sanitizer';
import { AnalyticsAction } from 'lib/analytics';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { useCardInteractionLog } from '../../hooks';
import { KlueCardActionMenu } from '../klue-card-action-menu';

import type { KlueCardHeaderProps } from './KlueCardHeader.types';

function KlueCardHeader(props: KlueCardHeaderProps) {
  const {
    isCardViewerContent,
    variant = 'default',
    cardData,
    featured,
    onTitleHover,
    hideMenus,
    onTitleClick,
    rank,
    profileId = props.cardData?.board?.profileId, // TODO Fix KlueCard type
  } = props;
  const { isCardViewerUpdateEnabled, isNewCardEditorEnabled, isCurator } =
    useAuth();
  const { open: openCardViewer } = useCardViewer();
  const cardName = cardData?.data?.name;
  const isSearchVariation = variant === 'search';
  const { redirectToCardEditor } = useRedirect();
  const cardTitle =
    (isSearchVariation && cardData?.data?.nameWithHighlight) || cardName || '';

  const htmlTitle = useMemo(
    () => ({
      __html: sanitize(cardTitle, {
        ALLOWED_TAGS: CARD_HEADER_ALLOWED_TAGS,
      }),
    }),
    [cardTitle],
  );

  const propsToOverride = featured
    ? {
        margin: {
          bottom: 'large',
          horizontal: 'large',
        },
        size: 'xlarge',
      }
    : {};

  const logCardInteraction = useCardInteractionLog();
  const handleOpenCardViewer = useCallback(
    (options?: { scrollToSources?: boolean; rank?: number | null }) => {
      if (cardData?.id) {
        openCardViewer(cardData.id, options);
        logCardInteraction({
          cardId: cardData.id,
          action: AnalyticsAction.expand,
          label: 'title',
          rank,
        });
      }
    },
    [cardData?.id, openCardViewer, logCardInteraction, rank],
  );

  const handleEditCard = useCallback(
    (options?: { rank?: number | null }) => {
      if (cardData?.id && profileId) {
        logCardInteraction({
          cardId: cardData.id,
          action: AnalyticsAction.edit,
          label: 'title',
          rank,
        });
        redirectToCardEditor({
          profileId,
          cardId: cardData.id,
        });
      }
    },
    [cardData?.id, logCardInteraction, profileId, rank, redirectToCardEditor],
  );

  const HeaderAnchor = useMemo(() => {
    return (props: any) => (
      <Anchor
        size="large"
        dangerouslySetInnerHTML={htmlTitle}
        onMouseOver={() => onTitleHover?.(true)}
        onMouseLeave={() => onTitleHover?.(false)}
        data-tracking-id={TRACKING_IDS.global.card.title}
        style={{ display: 'inline' }}
        {...props}
      />
    );
  }, [htmlTitle, onTitleHover]);

  if (cardData?.id === undefined) {
    return null;
  }

  if (isCardViewerUpdateEnabled) {
    if (isCardViewerContent) {
      return cardTitle ? (
        <Card.Header
          level={1}
          data-test-id={TEST_IDS.klueCard.header.cardViewer}
          className="card-static-html_title"
        >
          <div dangerouslySetInnerHTML={htmlTitle} />
        </Card.Header>
      ) : null;
    }

    if (isSearchVariation) {
      return cardTitle ? (
        <Card.Header
          level={1}
          style={{
            padding: tokens.spacing.large,
            paddingBottom: 0,
            whiteSpace: 'pre-wrap',
          }}
          data-test-id={TEST_IDS.klueCard.header.search}
          className="card-static-html_title"
        >
          <HeaderAnchor
            onClick={() =>
              isCurator && isNewCardEditorEnabled
                ? handleEditCard({ rank })
                : handleOpenCardViewer({ rank })
            }
          />
        </Card.Header>
      ) : null;
    }

    return (
      <Box
        direction="row"
        justify={cardTitle ? 'between' : 'end'}
        align="center"
        height={{ min: 'fit-content' }}
        margin={{ horizontal: 'large' }}
        pad={{ vertical: 'small' }}
        border={{ side: 'bottom', color: tokens.color.neutral.lightgrey.main }}
        data-test-id={TEST_IDS.klueCard.header.default}
      >
        {cardTitle && (
          <Box fill justify="center">
            <Card.Header level={1} className="card-static-html_title">
              <HeaderAnchor
                onClick={() =>
                  // eslint-disable-next-line no-nested-ternary
                  onTitleClick
                    ? onTitleClick()
                    : isCurator && isNewCardEditorEnabled
                    ? handleEditCard({ rank })
                    : handleOpenCardViewer({ rank })
                }
                data-test-id={TRACKING_IDS.global.card.title}
              />
            </Card.Header>
          </Box>
        )}
        {!hideMenus && cardData && (
          <KlueCardActionMenu
            cardId={cardData.id}
            sourcesCount={cardData?.sourcesCount}
            profileId={profileId}
            rank={rank}
          />
        )}
      </Box>
    );
  }

  return cardTitle ? (
    <Card.Header
      level={1}
      data-test-id={TEST_IDS.klueCard.header.legacy}
      className="card-static-html_title"
      style={{ padding: tokens.spacing.large, paddingBottom: 0 }}
      {...propsToOverride}
    >
      <div dangerouslySetInnerHTML={htmlTitle} />
    </Card.Header>
  ) : null;
}

export default KlueCardHeader;
