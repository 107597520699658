import isEqual from 'lodash/isEqual';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';

import type { CardType } from 'api/api.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

export type VisibilityGroup = { id: number; name: string };
export type PermissionsType = {
  allAccess: boolean;
  isDraft: boolean;
  visibilityGroups: VisibilityGroup[];
};

const useCardVisibilityGroups = ({ card }: { card?: CardType }) => {
  const cardPermissions = useMemo(
    () => ({
      allAccess: card?.allAccess ?? false,
      isDraft: card?.isDraft ?? true,
      visibilityGroups: card?.visibilityGroups || [],
    }),
    [card],
  );
  const [permissions, setPermissions] = useState(cardPermissions);
  const initialPermissionsRef = useRef(cardPermissions);
  const initialCardId = useRef(card?.id);

  useEffect(() => {
    if (card?.id !== initialCardId.current) {
      initialPermissionsRef.current = cardPermissions;
      initialCardId.current = card?.id;
      setPermissions(cardPermissions);
    }
  }, [card, cardPermissions]);

  const updatePermissionsWithCardsDataResult = useCallback(
    (
      card: Pick<CardType, 'id'> | undefined,
      cardPermissionCardsDataArray:
        | GetCardPermissionCardsDataResult
        | undefined,
    ) => {
      if (!cardPermissionCardsDataArray) return;

      const updatedPermissions =
        cardPermissionCardsDataArray.length === 1
          ? cardPermissionCardsDataArray[0]
          : cardPermissionCardsDataArray.find((p) => p.id === card?.id);

      if (updatedPermissions) {
        setPermissions((prev) => ({
          allAccess: Boolean(updatedPermissions.allAccess),
          isDraft: updatedPermissions.isDraft ?? true,
          visibilityGroups:
            updatedPermissions.visibilityGroups || prev.visibilityGroups,
        }));
      }
    },
    [],
  );

  const handleUpdateVisibilityGroups = useCallback(
    (
      card: any,
      cardPermissionCardsData: GetCardPermissionCardsDataResult | undefined,
    ) => {
      updatePermissionsWithCardsDataResult(card, cardPermissionCardsData);
    },
    [updatePermissionsWithCardsDataResult],
  );

  const isDirty = useMemo(
    () => !isEqual(permissions, initialPermissionsRef.current),
    [permissions],
  );

  return {
    isDraft: permissions.isDraft,
    allAccess: permissions.allAccess,
    visibilityGroups: permissions.visibilityGroups,
    handleUpdateVisibilityGroups,
    isDirty,
  };
};

export default useCardVisibilityGroups;
